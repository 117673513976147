import React from 'react';

interface PaginationProps {
  rowsPerPage: number;
  rowCount: number;
  onChangePage: (page: number) => void;
  currentPage: number;
}

const CustomPagination: React.FC<PaginationProps> = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage,
}) => {
  const pageCount = Math.ceil(rowCount / rowsPerPage);
  const pagesToShow = 4; // Número de páginas a mostrar antes y después de los ...
  const pages = [];

  if (pageCount > pagesToShow * 2 + 3) {
    let startPage = Math.max(2, currentPage - pagesToShow);
    let endPage = Math.min(pageCount - 1, currentPage + pagesToShow);

    if (currentPage - 1 <= pagesToShow) {
      endPage = 1 + pagesToShow * 2;
    }

    if (pageCount - currentPage <= pagesToShow) {
      startPage = pageCount - pagesToShow * 2;
    }

    pages.push(
      <button
        type="button"
        key={1}
        onClick={() => onChangePage(1)}
        className={`btn ${currentPage === 1 ? 'active' : ''}`}
      >
        1
      </button>
    );

    if (startPage > 2) {
      pages.push(<span key="start-ellipsis">...</span>);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          type="button"
          key={i}
          onClick={() => onChangePage(i)}
          className={`btn ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    if (endPage < pageCount - 1) {
      pages.push(<span key="end-ellipsis">...</span>);
    }

    pages.push(
      <button
        type="button"
        key={pageCount}
        onClick={() => onChangePage(pageCount)}
        className={`btn ${currentPage === pageCount ? 'active' : ''}`}
      >
        {pageCount}
      </button>
    );
  } else {
    // Si no hay suficientes páginas como para necesitar ..., simplemente muestra todas las páginas.
    for (let i = 1; i <= pageCount; i++) {
      pages.push(
        <button
          type="button"
          key={i}
          onClick={() => onChangePage(i)}
          className={`btn ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }
  }

  return <div>{pages}</div>;
};

export default CustomPagination;
