import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import TagsComponent from '../../../../components/Forms/tags/tags.component';
import { TAGS_DATA } from '../../../../utils/tags';
import { Accordion } from 'react-bootstrap';
import AcompananteDataComponent from '../../../../components/Forms/adminDetail/acompananteDetail.component';
import { IAdmin } from '../../../../types/adminInterface';
import { getSession } from '../../../../utils/helpers';

const OwnerDetailScreen: React.FC = () => {
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const tagsShow = ['tag-10']; // Definir los id de las tags que se quieren mostrar

  const tagsFiltered = TAGS_DATA.filter((tag) => tagsShow.includes(tag.id));
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Esto navegará hacia atrás en la historia del navegador
  };

  useEffect(() => {
    const session = getSession();
    if (session) {
      setAdmin(session);
    }
  }, []);

  const formatDate = (date: string): string => {
    if (!date) return '';
    return format(new Date(date), 'dd/MM/yyyy');
  };

  return (
    <>
      <HeaderShared />
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom">
            <div className="row">
              <div className="col-12 mb-3">
                <button
                  type="button"
                  onClick={handleGoBack}
                  className="btn--back"
                >
                  <i className="icon icon--back"></i> Regresar
                </button>
              </div>
              <div className="view-head__data col-12">
                <div className="row">
                  <div className="view-detail col-md-8 col-12">
                    <h5>Mi perfil</h5>
                    <h1 className="text-40">{`${admin.username || ''}`}</h1>
                    <p className="text-20">{`${admin.principalRol || ''}`}</p>
                    <div className="view-detail__tags">
                      <TagsComponent tags={tagsFiltered} />
                    </div>
                    <div className="col-md-8 col-12 col-reset mb-4">
                      <table className="table-user-data">
                        <tbody>
                          <tr>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--message-clip"></i>{' '}
                                Registrada:
                              </p>
                            </td>
                            <td>
                              {`${formatDate(
                                admin.createdAt?.toString() || ''
                              )}`}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--user-clip"></i>{' '}
                                Acompañamientos:
                              </p>
                            </td>
                            <td>14 mujeres</td>
                          </tr>
                          <tr>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--pin"></i> Colectiva:
                              </p>
                            </td>
                            <td>Villa del Mar</td>
                          </tr>
                          <tr>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--edit-clip"></i>Rol
                                Principal:
                              </p>
                            </td>
                            <td>Acompañante</td>
                          </tr>
                          <tr>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--edit-clip"></i> Rol
                                Secundario:
                              </p>
                            </td>
                            <td>
                              <span className="text-gray600">{`${
                                admin.secondaryRol || 'Sin Asignar'
                              }`}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <aside className="view-detail__action no-flex  col-md-4 col-12">
                    <div className="view-detail__action-col col-12 col-reset mb-3">
                      <a href="/" className="btn btn--chat">
                        Ver Chat <i className="icon icon--chat"></i>
                      </a>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
            <div className="view-body__info col-md-9 col-12">
              <div className="col-12 mt-3">
                <Accordion defaultActiveKey="0" className="accordion-regular">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Información personal</Accordion.Header>
                    <Accordion.Body>
                      <AcompananteDataComponent admin={admin} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default OwnerDetailScreen;
