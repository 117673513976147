import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IUser } from '../../../../types/userInterface';

interface ModalCambiarColectivaSuccessComponentProps {
  show: boolean;
  onHide: () => void;
  user: Partial<IUser>;
}

const ModalCambiarColectivaSuccessComponent: React.FC<
  ModalCambiarColectivaSuccessComponentProps
> = ({ show, onHide, user }) => {
  const [personalData, setPersonalData] = useState<Partial<IUser>>({});

  useEffect(() => {
    if (user) {
      setPersonalData(user);
    }
  }, [user]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal--gray"
      >
        <Modal.Header className="justifify-content-center">
          <Modal.Title id="contained-modal-title-vcenter" className="col-12">
            <h2 className="h3 text-center">Cambio realizado con éxito</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 d-flex justify-content-center">
            <figure className="image-280">
              <img
                src="/assets/images/illustrations/remember.png"
                alt="Success"
              />
            </figure>
          </div>
          <p className="text-center mb-0">
            Se trasladó a <strong>{`${personalData.username}`}</strong> (No.
            Ficha {`${personalData.userId}`}) a colectiva:
          </p>
          <p className="text-center mb-0">
            <strong>{`${
              personalData.colectiva?.value?.toUpperCase() || ''
            }`}</strong>
          </p>
          <p className="text-center mb-0">
            Los cambios ya podrán ser visibles en su perfil
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12 d-flex justify-content-center mb-3">
            <button
              type="submit"
              className="btn btn--type1 btn--290"
              onClick={onHide}
            >
              Regresar a perfil
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalCambiarColectivaSuccessComponent;
