import React, { useState, useRef } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import ModalCambiarColectivaComponent from '../Modal/ModalCambiarColectiva/modalCambiar.component';
import ModalCambiarColectivaSuccessComponent from '../Modal/ModalCambiarColectiva/modalCambiarSuccess.component';
import ModalDisabledComponent from '../Modal/ModalDisabled/modalDisabled.component';
import ModalDisabledSuccessComponent from '../Modal/ModalDisabled/modalDisabledSuccess.component';
import ModalDeleteComponent from '../Modal/ModalDelete/modalDelete.component';
import ModalDeleteSuccessComponent from '../Modal/ModalDelete/modalDeleteSuccess.component';
import ModalAsignarRolComponent from '../Modal/ModalAsignarRol/modalAsignarRol.component';
import ModalAsignarRolSuccessComponent from '../Modal/ModalAsignarRol/modalAsignarRolSuccess.component';

type ActionsAcompananteButtonComponentProps = {
  // Define your props here
};

const ActionsAcompananteButtonComponent: React.FC<
  ActionsAcompananteButtonComponentProps
> = () => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [modalShowCambiar, setModalShowCambiar] = React.useState(false);
  const [modalShowCambiarSuccess, setModalShowCambiarSuccess] = useState(false);
  const [modalShowDisabled, setModalShowDisabled] = React.useState(false);
  const [modalShowDisabledSuccess, setModalShowDisabledSuccess] =
    useState(false);
  const [modalShowDelete, setModalShowDelete] = React.useState(false);
  const [modalShowDeleteSuccess, setModalShowDeleteSuccess] = useState(false);
  const [modalShowAsignarRol, setModalShowAsignarRol] = React.useState(false);
  const [modalShowAsignarRolSuccess, setModalShowAsignarRolSuccess] =
    useState(false);

  const handleClose = () => setShow(false);

  // Cambiar
  const openModalCambiar = () => {
    setModalShowCambiar(true);
    setShow(false);
  };

  const handleModalCambiarSuccess = () => {
    setModalShowCambiar(false);
    setModalShowCambiarSuccess(true);
  };

  const handleModalCambiarClose = () => {
    setModalShowCambiar(false);
  };

  const handleModalCambiarSuccessClose = () => {
    setModalShowCambiarSuccess(false);
    setShow(false);
  };

  // Disabled
  const openModalDisabled = () => {
    setModalShowDisabled(true);
    setShow(false);
  };

  const handleModalDisabledSuccess = () => {
    setModalShowDisabled(false);
    setModalShowDisabledSuccess(true);
  };

  const handleModalDisabledClose = () => {
    setModalShowDisabled(false);
  };

  const handleModalDisabledSuccessClose = () => {
    setModalShowDisabledSuccess(false);
    setShow(false);
  };

  // Delete
  const openModalDelete = () => {
    setModalShowDelete(true);
    setShow(false);
  };

  const handleModalDeleteSuccess = () => {
    setModalShowDelete(false);
    setModalShowDeleteSuccess(true);
  };

  const handleModalDeleteClose = () => {
    setModalShowDelete(false);
  };

  const handleModalDeleteSuccessClose = () => {
    setModalShowDeleteSuccess(false);
    setShow(false);
  };

  // Asignar Rol
  const openModalAsignarRol = () => {
    setModalShowAsignarRol(true);
    setShow(false);
  };

  const handleModalAsignarRolSuccess = () => {
    setModalShowAsignarRol(false);
    setModalShowAsignarRolSuccess(true);
  };

  const handleModalAsignarRolClose = () => {
    setModalShowAsignarRol(false);
  };

  const handleModalAsignarRolSuccessClose = () => {
    setModalShowAsignarRolSuccess(false);
    setShow(false);
  };

  const handleOverlayClose = () => {
    handleClose();
  };

  return (
    <>
      <ModalCambiarColectivaComponent
        show={modalShowCambiar}
        onHide={handleModalCambiarClose}
        onSuccess={handleModalCambiarSuccess}
        user={{}}
      />
      <ModalCambiarColectivaSuccessComponent
        show={modalShowCambiarSuccess}
        onHide={handleModalCambiarSuccessClose}
        user={{}}
      />
      <ModalDisabledComponent
        show={modalShowDisabled}
        onHide={handleModalDisabledClose}
        onSuccess={handleModalDisabledSuccess}
        user={{}}
      />
      <ModalDisabledSuccessComponent
        user={{}}
        show={modalShowDisabledSuccess}
        onHide={handleModalDisabledSuccessClose}
      />
      <ModalDeleteComponent
        show={modalShowDelete}
        onHide={handleModalDeleteClose}
        onSuccess={handleModalDeleteSuccess}
      />
      <ModalDeleteSuccessComponent
        show={modalShowDeleteSuccess}
        onHide={handleModalDeleteSuccessClose}
      />
      <ModalAsignarRolComponent
        show={modalShowAsignarRol}
        onHide={handleModalAsignarRolClose}
        onSuccess={handleModalAsignarRolSuccess}
      />
      <ModalAsignarRolSuccessComponent
        show={modalShowAsignarRolSuccess}
        onHide={handleModalAsignarRolSuccessClose}
      />
      <button
        type="button"
        className="btn btn--type3 btn--100"
        ref={target}
        onClick={() => setShow(!show)}
      >
        + Más acciones
      </button>
      <Overlay
        target={target.current}
        show={show}
        placement={window.innerWidth < 768 ? 'bottom' : 'left'}
        rootClose
        onHide={handleOverlayClose}
      >
        <Tooltip className="tooltip--white">
          <ul className="tooltip-options">
            <li>
              <a href="/editar-administrador">
                <i className="icon icon--edit"></i>
                Editar información
              </a>
            </li>
            <li>
              <button type="button" onClick={openModalCambiar}>
                <i className="icon icon--change"></i>
                Cambiar a otra colectiva
              </button>
            </li>
            <li>
              <a href="/evaluaciones">
                <i className="icon icon--evaluaciones"></i>
                Evaluaciones
              </a>
            </li>
            <li>
              <button type="button" onClick={openModalDisabled}>
                <i className="icon icon--remove"></i>
                Desactivar temporalmente
              </button>
            </li>
            <li>
              <button type="button" onClick={openModalAsignarRol}>
                <i className="icon icon--remove"></i>
                Asignar rol secundario
              </button>
            </li>
            <li>
              <button type="button" onClick={openModalDelete}>
                <i className="icon icon--delete"></i>
                <span className="text-red">Eliminar cuenta</span>
              </button>
            </li>
          </ul>
        </Tooltip>
      </Overlay>
    </>
  );
};

export default ActionsAcompananteButtonComponent;
