import React from 'react';

interface WomenComponentProps {
  // Define the props for your component here
}

const WomenComponent: React.FC<WomenComponentProps> = () => {
  // Implement your component logic here

  return (
    <div className="col-12 col-reset">
      <p className="text-64 text-500 mb-0">10,300</p>
      <p className="text-24 text-titular text-700 mb-0">Mujeres atendidas hasta hoy</p>
      <p>Último registro: 27 de jun 2023</p>
    </div>
  );
};

export default WomenComponent;