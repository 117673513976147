import React from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';

const TallerEditSuccessScreen: React.FC= () => {
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            

            <div className="module-bottom row">
            <div className="col-lg-6 col-12 mx-auto text-center">
              <div className="d-flex justify-content-center">
                <figure className="image-490">
                  <img src="/assets/images/illustrations/cat.png" alt="Confirmación" />
                </figure>
              </div>
              <h1 className="text-40 mb-3">¡ Taller actualizado !</h1>
              <p>Se registraron cambios en el taller “NO ESTAS SOLA” de manera exitosa, serán visibles y se les notificará a las mujeres vía Whatsapp</p>

              
            </div>
          </div>
          <div className="form-row  form--last">
            <a href="/talleres"
            className="btn btn--type1 btn--270"
          >Regresar a talleres</a>

          </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  )
}

export default TallerEditSuccessScreen;
