import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';

interface ModalDeleteComponentProps {
  show: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const ModalDeleteComponent: React.FC<ModalDeleteComponentProps> = ({ show, onHide, onSuccess }) => {
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] = useState(false);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Evitar recarga de la página al enviar el formulario
    
    // Simulando el envío exitoso del formulario
    // Aquí debes agregar la lógica real para enviar el formulario al servidor
    // y establecer setFormSubmittedSuccessfully(true) en la devolución de llamada de éxito
    setFormSubmittedSuccessfully(true);
    onSuccess();
  };

  const handleModalClose = () => {
    onHide();

    // Si el formulario se envió con éxito, reiniciar el estado de formSubmittedSuccessfully
    if (formSubmittedSuccessfully) {
      setFormSubmittedSuccessfully(false);
    }
  };


  return (
    <>
      <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal--gray"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="text-center"><i className="icon icon--delete-black"></i> Eliminar cuenta</h2>
        </Modal.Title>
        <button type="button" className="btn-close" onClick={handleModalClose} aria-label="Close"></button>
      </Modal.Header>
      <Modal.Body>
        <h3 className="h4 text-center mb-5">¿Deseas eliminar esta cuenta?</h3>
        <form onSubmit={handleSubmit}>
          <p className="text-center">Si aceptas, todos los datos e información <strong>se eliminarán permanentemente</strong> de la base de datos de <strong>Las Amigas Y En La Casa</strong>.</p>
          <p className="text-center">Recuerda que, una vez eliminada, no podrás recuperar la cuenta ni su contenido.</p>
          <div className="double-btn-action pt-5">
            <button
              type="button"
              className="btn btn--type2"
              onClick={handleModalClose} 
            >Cancelar</button>
            <button
              type="submit"
              className="btn btn--type1"
            >Eliminar</button>
          </div>
        </form>
      </Modal.Body>
      
    </Modal>
    </>
  );
};

export default  ModalDeleteComponent;