import React from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import ForgotPasswordComponent from '../../../components/Auth/ForgotPassword/forgotPassword.component';

const ForgotPasswordScreen = () => {
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="auth-row module-top row">
              <div className="col-md-5 col-12">
                <h1>¿Olvidaste tu contraseña?</h1>
                <article className="mb-3 mt-3">
                  <p>
                    No te preocupes, ingresa el correo electrónico con el que te
                    registraste y recibirías un correo de confirmación para
                    actualizar tu contraseña.
                  </p>
                </article>
                <ForgotPasswordComponent />
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img
                    src="/assets/images/illustrations/about.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default ForgotPasswordScreen;
