import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

interface ModalEntregasProps {
  show: boolean;
  onHide: () => void;
}
const ModalEntregasComponents: React.FC<ModalEntregasProps> = ({ show, onHide }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const checkFormValidity = () => {
    const requiredFields = document.querySelectorAll('.requiredField');
    const allFieldsFilled = Array.from(requiredFields).every(field => {
      const inputField = field as HTMLInputElement; // Aserción de tipo a HTMLInputElement
  
      if (inputField.type === 'checkbox' || inputField.type === 'radio') {
        return Array.from(requiredFields).some(f => {
          const input = f as HTMLInputElement;
          return input.name === inputField.name && input.checked;
        });
      }
      return inputField.value.trim() !== '';
    });
    setIsFormValid(allFieldsFilled);
  };
  

  useEffect(() => {
    // Llama a checkFormValidity cuando el modal se muestra
    if (show) {
      checkFormValidity();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="col-12 d-flex justify-content-center mb-4">
            <i className="icon icon--derivar"></i>
          </div>
          <h2 className="text-40 text-center text-500 pb-2">No se entregaron todos los kits</h2>
          <p className="text-24 text-center">Recuerda registrar los detalles por los que concluyes la entrega de forma incompleta</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
        <div className="form-row">
              <label className="form-label label--icon">
               Elige el o los motivos por los que no se completó la entrega del listado <small className="required">*</small>
              </label>
              <p className="text-gray800">Puedes seleccionar más de una opción si es tu caso</p>
              <div className="row-check-2 row">
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="motivosEntrega" id="tiempo" value="Esperé mucho tiempo y no llegaron todas las mujeres"   onChange={checkFormValidity}/>
                    <label className="form-check-label" htmlFor="tiempo">
                    Esperé mucho tiempo y no llegaron todas las mujeres
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="motivosEntrega" id="peligro" value="Estuvo en peligro mi integridad durante la entrega"   onChange={checkFormValidity}/>
                    <label className="form-check-label" htmlFor="peligro">
                    Estuvo en peligro mi integridad durante la entrega
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="motivosEntrega" id="cancelacion" value="Las mujeres a las que no les entregué me cancelaron en último momento"   onChange={checkFormValidity}/>
                    <label className="form-check-label" htmlFor="cancelacion">
                    Las mujeres a las que no les entregué me cancelaron en último momento
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input className="form-check-input requiredField" type="checkbox" name="motivosEntrega" id="otro" value="Otras razones"   onChange={checkFormValidity}/>
                    <label className="form-check-label" htmlFor="otro">
                    Otras razones
                    </label>
                  </div>
                </div>
              
              </div>
             
            </div>
          <div className="form-row">
            <label className="form-label label--icon">
            ¿Qué deseas hacer con las entregas no realizadas?
            </label>
            <div className="form-check-multi">
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="entregasPendientes" id="derivarlas" value="derivarlas" defaultChecked  onChange={checkFormValidity}/>
                <label className="form-check-label" htmlFor="derivarlas">
                Derivarlas a un nuevo listado de entrega
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input requiredField" type="radio" name="entregasPendientes" id="otro" value="otro"  onChange={checkFormValidity}/>
                <label className="form-check-label" htmlFor="otro">
                Que lo decida la acompañante respectiva
                </label>
              </div>
             
            </div>
          </div>
         
            <div className="form-row">
              <label className="form-label">¿Cuáles son los motivos adicionales de la derivación?</label>
              <textarea
                className="form-control"
                placeholder="Ingresa los motivos adicionales" id="motivos"
              ></textarea>
            </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          <button type="submit" className="btn btn--type1 btn--370" onClick={onHide} disabled={!isFormValid}>Confirmar entrega</button>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn--type3 btn--370" onClick={onHide}>Cancelar</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEntregasComponents;
