import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import TallerItemComponent from '../../../../components/Elements/Talleres/taller.component';
import { getSession } from '../../../../utils/helpers';
import {
  deleteWorkshop,
  getWorkshop,
  updateWorkshop,
} from '../../../../api/workshopApi';
import { IWorkshop } from '../../../../types/workshopInterface';
import LoaderComponent from '../../../../components/Loader/loader.component';

const TalleresConfirmScreen: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [workshop, setWorkshop] = useState<Partial<IWorkshop>>({});

  const { id } = useParams<{ id: string }>();

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    }).then(() => {
      navigate('/talleres');
    });
  };

  const fetchWorkshop = async () => {
    try {
      setLoading(true);
      if (!id) {
        throw new Error('No se pudo obtener el id del taller');
      }
      const session = getSession();
      const response = await getWorkshop(id, session?.token || '');
      setWorkshop(response);
    } catch (error) {
      setError('Ocurrió un error al obtener los datos del taller');
    } finally {
      setLoading(false);
    }
  };

  const updateWorkshopAsActive = async () => {
    try {
      setLoading(true);
      if (!id) {
        throw new Error('No se pudo obtener el id del taller');
      }
      const session = getSession();
      await updateWorkshop(
        id,
        { status: workshop.status === 'pending' ? 'active' : workshop.status },
        session?.token || ''
      );
      navigate(`/creacion-finalizada/${workshop._id}`);
    } catch (error) {
      setError('Ocurrió un error al actualizar el taller');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteWorkshop = async () => {
    try {
      setLoading(true);
      if (!id) {
        throw new Error('No se pudo obtener el id del taller');
      }
      const session = getSession();
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Esta acción no se puede deshacer',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteWorkshop(id, session?.token || '');
          navigate('/talleres');
        }
      });
    } catch (error) {
      setError('Ocurrió un error al eliminar el taller');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchWorkshop();
  }, []);
  return (
    <>
      <HeaderShared />
      {loading && <LoaderComponent />}
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <a href={`/crear-taller/${workshop._id}`} className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-md-5 col-12">
                <h1>
                  Confirma los datos del taller {`${workshop.name || ''}`}
                </h1>
                <p className="text-20">
                  Así verán tu taller las mujeres de la plataforma. Confirma si
                  los datos están correctos para dar de alta tu taller.
                </p>
                <div className="row justify-content-center create-taller-item">
                  <TallerItemComponent workshop={workshop} />
                </div>
                <div className="row justify-content-center ">
                  <div className="col-12 col-reset mb-4">
                    <a
                      className="btn btn--type1 btn--100"
                      onClick={updateWorkshopAsActive}
                    >
                      Terminar registro
                    </a>
                  </div>
                  <div className="col-12 col-reset">
                    <a
                      href={`/crear-taller/${workshop._id}`}
                      className="btn btn--type2 btn--100"
                    >
                      Editar información
                    </a>
                  </div>
                  <div className="col-12 col-reset">
                    <a
                      className="btn btn--danger btn--100"
                      onClick={handleDeleteWorkshop}
                    >
                      Borrar taller
                    </a>
                  </div>
                </div>
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img
                    src="/assets/images/illustrations/remember.png"
                    alt="Editar taller"
                  />
                </figure>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default TalleresConfirmScreen;
