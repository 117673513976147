import React, {useState, useEffect} from 'react';
import HeaderAdminShared from '../../../shared/Header/headerAdmin.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import { ADMIN_DATA } from '../../../utils/adminUsers';
import ReactSearchAutocomplete from 'react-search-autocomplete/dist/components/ReactSearchAutocomplete';
import UserTableComponent from '../../../components/Elements/DataTable/userTable.component';
import { Accordion } from 'react-bootstrap';


interface Item {
  id: number;
  name: string;
}

const SearchScreen: React.FC = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const handleOnSearch = (string: string, results: Item[]) => {
    console.log(string, results);
  }

  const handleOnSelect = (item: Item) => {
    // the item selected
    console.log(item)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const handleOnHover = (result: Item) => {
    // the item hovered
    console.log(result)
  }

  const formatResult = (item: Item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }

  useEffect(() => {
    const updateMedia = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);


  return (
    <>
      <HeaderAdminShared />
      <section className="module container">
        <div className="body-row row mb-4">
          <div className="col-12">
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <a href="/equipo-de-las-amigas" className="btn--back"><i className="icon icon--back"></i> Regresar</a>
              </div>
              <div className="col-12">
                <div className="form-search-complete mb-4">
                  <div className="autocomplete-search search--100">
                    <ReactSearchAutocomplete
                      items={ADMIN_DATA}
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      onFocus={handleOnFocus}
                      autoFocus
                      formatResult={formatResult}
                      placeholder="Buscar colectiva"
                      className="autocomplete-search__input"
                    />
                  </div>
                </div>
                <h2 className="h3">Tus resultados para <span className="text-purple500">“Paulina”</span></h2>
              </div>
            </div>
          </div>
        </div>
        <div className="view-body module-top40">
          <div className="row">
            <aside className="dashboard-aside col-md-3 col-12">
              <div className="dashboard-aside__inner">
              {!isMobileView ? (
                <>
                  <h4 className="mb-0">¿Qué quieres buscar?</h4>
                    <hr />
                    <ul className="search-checks-list">
                      <li>
                        <div className="form-check check--small">
                          <input className="form-check-input" type="checkbox" value="" id="lider" />
                          <label className="form-check-label" htmlFor="lider">
                            Líder de colectiva
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check check--small">
                          <input className="form-check-input" type="checkbox" value="" id="tallerista" />
                          <label className="form-check-label" htmlFor="tallerista">
                            Tallerista
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check check--small">
                          <input className="form-check-input" type="checkbox" value="" id="acompanante" />
                          <label className="form-check-label" htmlFor="acompanante">
                            Acompañante
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check check--small">
                          <input className="form-check-input" type="checkbox" value="" id="entregadora" />
                          <label className="form-check-label" htmlFor="entregadora">
                            Entregadora
                          </label>
                        </div>
                      </li>
                    </ul>
                </>
                ) : (
                    <Accordion >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>¿Qué quieres buscar?</Accordion.Header>
                      <Accordion.Body>
                        <ul className="search-checks-list">
                          <li>
                            <div className="form-check check--small">
                              <input className="form-check-input" type="checkbox" value="" id="lider" />
                              <label className="form-check-label" htmlFor="lider">
                                Líder de colectiva
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="form-check check--small">
                              <input className="form-check-input" type="checkbox" value="" id="tallerista" />
                              <label className="form-check-label" htmlFor="tallerista">
                                Tallerista
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="form-check check--small">
                              <input className="form-check-input" type="checkbox" value="" id="acompanante" />
                              <label className="form-check-label" htmlFor="acompanante">
                              Acompañante
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="form-check check--small">
                              <input className="form-check-input" type="checkbox" value="" id="entregadora" />
                              <label className="form-check-label" htmlFor="entregadora">
                                Entregadora
                              </label>
                            </div>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
               
              
              </div>
            </aside>
            <div className="dashboard-info col-md-9 col-12">
              <h4 className="mb-4">Resultados</h4>
              <UserTableComponent />
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default SearchScreen;