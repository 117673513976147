export const DONATIONS_DATA = [
  { label: "Documento por paypal", amount: 180 },
  { label: "Documento por Stripe", amount: 150 },
  { label: "Documento por paypal", amount: 2500 },
  { label: "Documento por Stripe", amount: 500 },
  { label: "Documento por paypal", amount: 1000 },
  { label: "Documento por Stripe", amount: 750 },
  { label: "Documento por paypal", amount: 200 },
  { label: "Documento por Stripe", amount: 300 },
  { label: "Documento por paypal", amount: 400 },
  { label: "Documento por Stripe", amount: 600 },
  { label: "Documento por paypal", amount: 800 },
  { label: "Documento por Stripe", amount: 900 },
  { label: "Documento por paypal", amount: 1200 },
  { label: "Documento por Stripe", amount: 1500 },
  { label: "Documento por paypal", amount: 1800 },
  { label: "Documento por Stripe", amount: 2100 },
  { label: "Documento por paypal", amount: 2400 },
  { label: "Documento por Stripe", amount: 2700 },
  { label: "Documento por paypal", amount: 3000 },
  { label: "Documento por Stripe", amount: 3300 },
  { label: "Documento por paypal", amount: 3600 },
  { label: "Documento por Stripe", amount: 3900 },
  { label: "Documento por paypal", amount: 4200 }
];