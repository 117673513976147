import React, { useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IUser } from '../../../../types/userInterface';
import { fetchStrapiDocs } from '../../../../api/strapi';
import { strapiDoc } from '../../../../types/strapiDocs';
import LoaderComponent from '../../../Loader/loader.component';

interface ModalCambiarColectivaComponentProps {
  user: Partial<IUser>;
  show: boolean;
  onHide: () => void;
  onSuccess: (user: Partial<IUser>) => void;
}

const ModalCambiarColectivaComponent: React.FC<
  ModalCambiarColectivaComponentProps
> = ({ user, show, onHide, onSuccess }) => {
  const [loading, setLoading] = useState(true);
  const [personalData, setPersonalData] = useState<Partial<IUser>>({});
  const [colectivaList, setColectivaList] = useState<strapiDoc[]>([]);
  const [selectedColectiva, setSelectedColectiva] = useState<string>('default');

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedColectiva(event.target.value);
  };

  const isButtonDisabled = selectedColectiva === 'default';

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onHide();
    onSuccess({
      _id: personalData._id,
      colectiva: {
        documentId: selectedColectiva,
        value: colectivaList.find(
          (colectiva) => colectiva.id === Number(selectedColectiva)
        )?.attributes.nombre,
      },
    });
  };

  const handleModalClose = () => {
    onHide();
  };

  const fetchColectivas = async () => {
    try {
      setLoading(true);
      const query = {
        sort: ['nombre:asc'],
        filters: { pais: { $eq: 4 } }, // 4 es el id de Chile
        pagination: { pageSize: 100 },
        populate: '*',
      };
      const colectivas = await fetchStrapiDocs('/colectivas', query);
      setColectivaList(colectivas.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setPersonalData(user);
      setSelectedColectiva(`${user?.colectiva?.documentId}` || 'default');
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchColectivas();
    }
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal--gray"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="text-center">
              <i className="icon icon--derivar-small"></i> Cambiar de colectiva
            </h2>
          </Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={handleModalClose}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          {loading && <LoaderComponent />}
          <h3 className="h4 text-center mb-5">
            Selecciona la Colectiva a la que la quieras trasladar
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="form-row pb-3">
              <label className="form-label label--icon">
                Colectiva <small className="required">*</small>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Colectivas disponibles</Tooltip>}
                >
                  <span className="material-icons-outlined icon">
                    help_outline
                  </span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select requiredField"
                aria-label="Colectiva"
                defaultValue={'default'}
                name="colectiva"
                value={selectedColectiva}
                onChange={handleSelectChange}
              >
                <option value="default">Selecciona una opción</option>
                {colectivaList.map((colectiva) => (
                  <option key={colectiva.id} value={colectiva.id}>
                    {colectiva.attributes.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-row d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn--type1 btn--290"
                disabled={isButtonDisabled}
              >
                Confirmar cambio
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCambiarColectivaComponent;
