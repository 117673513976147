import React from 'react';
import FooterShared from '../../../shared/Header/footer.shared';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import AccordionComplete from '../../../components/Elements/AccordionComplete/accordionComplete.component';
import { ZONES_DATA } from '../../../utils/zones';
import HeaderAdminShared from '../../../shared/Header/headerAdmin.shared';

interface Item {
  id: number;
  name: string;
}

const ColectivasScreen: React.FC = () => {
  const items = [
    {
      id: 0,
      name: 'Colectiva 1',
    },
    {
      id: 1,
      name: 'Colectiva 2',
    },
    {
      id: 2,
      name: 'Colectiva 3',
    },
    {
      id: 3,
      name: 'Colectiva 4',
    },
    {
      id: 4,
      name: 'Colectiva 5',
    },
  ];

  const handleOnSearch = (string: string, results: Item[]) => {
    console.log(string, results);
  };

  const handleOnSelect = (item: Item) => {
    // the item selected
    console.log(item);
  };

  const handleOnFocus = () => {
    console.log('Focused');
  };

  const handleOnHover = (result: Item) => {
    // the item hovered
    console.log(result);
  };

  const formatResult = (item: Item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    );
  };

  return (
    <>
      <HeaderAdminShared />
      <section className="module40 container">
        <div className="body-row row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mb-4">
                <a href="/inicio-administrador" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-12 mb-5">
                <h1 className="mb-4">Colectivas</h1>
                <p>
                  Puedes buscar por: <strong>Nombre, Zona, Líder</strong>
                </p>
                <div className="form-search-complete">
                  <div className="autocomplete-search">
                    <ReactSearchAutocomplete
                      items={items}
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      onFocus={handleOnFocus}
                      autoFocus
                      formatResult={formatResult}
                      placeholder="Buscar colectiva"
                      className="autocomplete-search__input"
                    />
                  </div>
                  <a href="/nueva-colectiva" className="btn btn--type1">
                    + Registrar una nueva colectiva
                  </a>
                </div>
              </div>
              <div className="col-12">
                {ZONES_DATA.map((zoneData, index) => (
                  <AccordionComplete
                    key={index}
                    zone={zoneData.zone}
                    colectivas={zoneData.colectivas}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default ColectivasScreen;
