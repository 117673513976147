import React, {useState, useEffect} from 'react';
import data from '../../../utils/comunas.json';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {COORDINADORAS_DATA} from '../../../utils/coordinadoras';


interface Props {
  // Define your component's props here
}

const NewColectivaComponent: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [comunas, setComunas] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedComuna, setSelectedComuna] = useState<string>(''); 
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [formularioValido, setFormularioValido] = useState(false);
  
  const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const region = e.target.value;
    setSelectedRegion(region);
    const regionData = data.regiones.find(r => r.region === region);
    setComunas(regionData ? regionData.comunas : []);
    setSelectedComuna(''); // Resetea la comuna seleccionada cuando cambia la región
    verifyFields();
  };

  const handleComunaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedComuna(e.target.value);
    verifyFields();
  };
  const handleCoordinadoraChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedComuna(e.target.value);
    verifyFields();
  };

  const handleSubmit = () => {
    navigate('/nueva-colectiva-finalizado');
  };

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll('.requiredField') as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
  
    camposRequeridos.forEach(campo => {
      if (campo.value === undefined || campo.value === null) {
        todosCompletos = false;
      } else {
        const campoCompleto = campo.value.trim() !== '';
        todosCompletos = todosCompletos && campoCompleto;
        console.log(campoCompleto)
      }
    });
    setFormularioValido(todosCompletos);
  };

  useEffect(() => {
    verifyFields();
  }, []);

  return (
    <div className="form-block">
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label htmlFor="region" className="form-label label--icon">
            Región chilena <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Es importante para nosotras saber en cuál territorio te ubicas, con el objetivo de brindarte información que manejemos respecto a servicios de salud disponibles en tu territorio. 
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <select
            className="form-select requiredField"
            aria-label="Tu región"
            onChange={handleRegionChange}
            defaultValue={'default'}
          >
            <option value="default" disabled>Elige una de las opciones</option>
            {data.regiones.map((region, index) => (
              <option key={index} value={region.region}>{region.region}</option>
            ))}
          </select>
        </div>
        <div className="form-row">
          <label htmlFor="comuna" className="form-label label--icon">
            Tu comuna <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Es importante para nosotras saber en cuál  territorio te ubicas, con el objetivo de brindarte información que manejemos respecto a servicios de salud disponibles en tu territorio. 
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <select
            className="form-select requiredField"
            aria-label="Tu comuna"
            onChange={handleComunaChange}
            defaultValue={'default'}
          >
            <option value="default" disabled>Elige una de las opciones</option>
            {comunas.map((comuna, index) => (
              <option key={index} value={comuna}>{comuna}</option>
            ))}
          </select>
        </div>
        <div className="form-row">
          <label htmlFor="name" className="form-label label--icon">
            Nombre de la colectiva <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Nombre de la colectiva 
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Escribe el nombre"
          />
        </div>
        <div className="form-row">
          <label htmlFor="email" className="form-label label--icon">
            Tu correo electrónico <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Tu correo electrónico 
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control requiredField"
            id="email"
            placeholder="Escribe aquí tu correo"
          />
        </div>
        <div className="form-row">
          <label htmlFor="email" className="form-label label--icon">
            Líder de colectiva <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                 Líder de colectiva
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <select
            className="form-select requiredField"
            aria-label="Tu región"
            defaultValue={'default'}
            onChange={handleCoordinadoraChange}
          >
            <option value="default" disabled>Elige una de las opciones</option>
            {COORDINADORAS_DATA.map((cordinadora, index) => (
              <option key={index} value={cordinadora.name}>{cordinadora.name}</option>
            ))}
          </select>
        </div>
        <div className="form-row">
          <label htmlFor="emailColectiva" className="form-label label--icon">
            Correo del líder de colectiva <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Correo del líder de colectiva
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control requiredField"
            id="emailColectiva"
            placeholder="Escribe el correo de la líder de esta colectiva"
          />
        </div>
        <div className="form-row form--last">
          <button
            type="submit"
            className="btn btn--type1"
            disabled={!formularioValido}
          >Registrar</button>
        </div>
      </form>
    </div>
  );
};

export default NewColectivaComponent;