import React from 'react';

interface GlobalScoreComponent {
  type: string;
  score: number;
}

const GlobalScoreComponent: React.FC<GlobalScoreComponent> = ({type, score}) => {
  const formattedScore = score.toFixed(1);

  return (
    <div className="quality-score">
      <p className="mb-0">Promedio:</p>
      <p className="mb-2"><strong>{type}</strong></p>
      <div className="quality-score__number">
        <i className="fa-solid fa-star text-purple500"></i>
        <span className="text-28 text-500">{formattedScore}</span>
      </div>
    </div>
  );
};

export default GlobalScoreComponent;