import React from 'react';
import HeaderAdminShared from '../../../shared/Header/headerAdmin.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import AmigaInfoComponent from '../../../components/Auth/NewAmiga/amigaInfo.component';
import { useLocation } from 'react-router-dom';
import { IAdmin } from '../../../types/adminInterface';

const NewAmigaInfoScreen: React.FC = () => {
  const { state } = useLocation();
  const admin = state.admin as IAdmin | undefined;

  return (
    <>
      <HeaderAdminShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <a href="/nueva-amiga" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-md-5 col-12">
                <h1>Registro de nueva amiga</h1>
                <p>Llena los campos que se piden para poder registrar.</p>
                <h2 className="h3">Información</h2>
                {admin && <AmigaInfoComponent admin={admin} />}
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img
                    src="/assets/images/illustrations/contact.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default NewAmigaInfoScreen;
