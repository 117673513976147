import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { setDefaultOptions } from 'date-fns';
import { es } from 'date-fns/locale';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import LoginScreen from './pages/Auth/Login/login.screen';
import ScrollToTop from './components/ScollToTop/scrollToTop.component';
import ForgotPasswordScreen from './pages/Auth/ForgotPassword/forgotPassword.screen';
import UpdatePasswordScreen from './pages/Auth/UpdatePassword/updatePassword.screen';
import AcompananteScreen from './pages/Dashboard/Index/Acompanante/acompanante.screen';
import DetailUserScreen from './pages/Dashboard/User/Detail/detail.screen';
import AccompanimentScreen from './pages/Dashboard/Accompaniment/accompaniment.screen';
import NotFoundScreen from './pages/NotFound/notFound.screen';
import TalleristaScreen from './pages/Dashboard/Index/indexTaller/tallerista.screen';
import TalleresScreen from './pages/Dashboard/Talleres/talleres.screen';
import TalleresAsistentesScreen from './pages/Dashboard/Talleres/Asistentes/talleresAsistentes.screen';
import TalleresChecklistScreen from './pages/Dashboard/Talleres/Checklist/checklist.screen';
import TalleresChecklistDoneScreen from './pages/Dashboard/Talleres/Checklist/checklistDone.screen';
import TalleresEditScreen from './pages/Dashboard/Talleres/Edit/tallerEdit.screen';
import TallerEditSuccessScreen from './pages/Dashboard/Talleres/Success/success.screen';
import TalleresCreateScreen from './pages/Dashboard/Talleres/Create/tallerCreate.screen';
import TalleresConfirmScreen from './pages/Dashboard/Talleres/Confirm/tallerConfirm.screen';
import TallerCreateSuccessScreen from './pages/Dashboard/Talleres/Success/successCreate.screen';
import EntregandoScreen from './pages/Dashboard/Index/Entregando/entregando.screen';
import ColectivaScreen from './pages/Dashboard/Index/Colectiva/colectiva.screen';
import CoordinadoraDetailScreen from './pages/Dashboard/Admin/Detail/coordinadoraDetail.screen';
import TalleristaDetailScreen from './pages/Dashboard/Admin/Detail/talleristaDetail.screen';
import AcompananteDetailScreen from './pages/Dashboard/Admin/Detail/acompananteDetail.screen';
import EntregadoraDetailScreen from './pages/Dashboard/Admin/Detail/entregadoraDetail.screen';
import AdminScreen from './pages/Dashboard/Index/Admin/admin.screen';
import ColectivasScreen from './pages/Dashboard/Colectivas/colectivas.screen';
import NewAmigaScreen from './pages/Auth/NewAmiga/newAmiga.screen';
import NewAmigaInfoScreen from './pages/Auth/NewAmiga/newAmigaInfo.screen';
import NewAmigaColectivaScreen from './pages/Auth/NewAmiga/newAmigaColectiva.screen';
import NewAmigaAuthScreen from './pages/Auth/NewAmiga/newAmigaAuth.screen';
import NewAmigaSuccessScreen from './pages/Success/successAccount.screen';
import EditUserScreen from './pages/Dashboard/User/Edit/edit.screen';
import EditUserSuccessScreen from './pages/Success/successEditUser.screen';
import EditAdminScreen from './pages/Dashboard/Admin/Edit/adminEdit.screen';
import EditAdminSuccessScreen from './pages/Success/successEditAdmin.screen';
import EvaluationAdminScreen from './pages/Dashboard/Admin/Evaluations/evaluationAdmin.screen';
import AllWomenScreen from './pages/Dashboard/Women/allWomen.screen';
import TeamScreen from './pages/Dashboard/Team/team.screen';
import CoordinadorasScreen from './pages/Dashboard/Team/coordinadoras.screen';
import AcompanantesScreen from './pages/Dashboard/Team/acompanantes.screen';
import TalleristasScreen from './pages/Dashboard/Team/talleristas.screen';
import EntregadorasScreen from './pages/Dashboard/Team/entregadoras.screen';
import SearchScreen from './pages/Dashboard/Search/search.component';
import NewColectivaScreen from './pages/Dashboard/Colectivas/newColectiva.screen';
import NewColectivaSuccessScreen from './pages/Success/successColectiva.screen';
import DonationsScreen from './pages/Dashboard/Donations/donations.screen';
import OwnerDetailScreen from './pages/Dashboard/Admin/Detail/ownerDetail.screen';
import PrivateRoute from './utils/PrivateRoute';

setDefaultOptions({ locale: es });
const ROLES = {
  ALL: 'All',
  ACOMPANANTE: 'Acompañante',
  ADMIN: 'Admin',
  ENTREGADORA: 'Entregadora',
  LIDER_COLECTIVA: 'Líder Colectiva',
  TALLERISTA: 'Tallerista',
};

function App() {
  return (
    <main className="app-main">
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute principalRol={[`${ROLES.ALL}`]}>
                  <TalleristaScreen />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<LoginScreen />} />
            <Route
              path="/recuperar-contrasena"
              element={<ForgotPasswordScreen />}
            />
            <Route
              path="/actualizar-contrasena"
              element={<UpdatePasswordScreen />}
            />
            <Route
              path="/inicio-acompanante"
              element={
                <PrivateRoute principalRol={[`${ROLES.ACOMPANANTE}`]}>
                  <AcompananteScreen />
                </PrivateRoute>
              }
            />
            <Route path="/inicio-tallerista" element={<TalleristaScreen />} />
            <Route
              path="/inicio-administrador"
              element={
                <PrivateRoute principalRol={[`${ROLES.ADMIN}`]}>
                  <AdminScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/detalle-usuario/:id"
              element={
                <PrivateRoute principalRol={[`${ROLES.ALL}`]}>
                  <DetailUserScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/detalle-coordinadora"
              element={<CoordinadoraDetailScreen />}
            />
            <Route
              path="/detalle-tallerista"
              element={<TalleristaDetailScreen />}
            />
            <Route
              path="/detalle-acompanante"
              element={<AcompananteDetailScreen />}
            />
            <Route
              path="/detalle-entregadora"
              element={<EntregadoraDetailScreen />}
            />
            <Route
              path="/mujeres-acompanando"
              element={<AccompanimentScreen />}
            />
            <Route path="/mujeres-entregando" element={<EntregandoScreen />} />
            <Route
              path="/colectivas"
              element={
                <PrivateRoute principalRol={[`${ROLES.ADMIN}`]}>
                  <ColectivasScreen />
                </PrivateRoute>
              }
            />
            <Route path="/nueva-colectiva" element={<NewColectivaScreen />} />
            <Route
              path="/nueva-colectiva-finalizado"
              element={<NewColectivaSuccessScreen />}
            />
            <Route
              path="/talleres"
              element={
                <PrivateRoute
                  principalRol={[`${ROLES.ADMIN}`, `${ROLES.TALLERISTA}`]}
                >
                  <TalleresScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/talleres-asistentes/:id"
              element={
                <PrivateRoute
                  principalRol={[`${ROLES.ALL}`, `${ROLES.TALLERISTA}`]}
                >
                  <TalleresAsistentesScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/talleres-checklist/:id"
              element={
                <PrivateRoute principalRol={[`${ROLES.TALLERISTA}`]}>
                  <TalleresChecklistScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/talleres-checklist-terminado/:id"
              element={
                <PrivateRoute principalRol={[`${ROLES.TALLERISTA}`]}>
                  <TalleresChecklistDoneScreen />
                </PrivateRoute>
              }
            />
            <Route path="/edicion-de-taller" element={<TalleresEditScreen />} />
            <Route path="/crear-taller">
              <Route
                path=""
                element={
                  <PrivateRoute
                    principalRol={[`${ROLES.ADMIN}`, `${ROLES.TALLERISTA}`]}
                  >
                    <TalleresCreateScreen />
                  </PrivateRoute>
                }
              />
              <Route
                path=":id"
                element={
                  <PrivateRoute
                    principalRol={[`${ROLES.ADMIN}`, `${ROLES.TALLERISTA}`]}
                  >
                    <TalleresCreateScreen />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="/confirmar-taller/:id"
              element={
                <PrivateRoute
                  principalRol={[`${ROLES.ADMIN}`, `${ROLES.TALLERISTA}`]}
                >
                  <TalleresConfirmScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/creacion-finalizada/:id"
              element={
                <PrivateRoute
                  principalRol={[`${ROLES.ADMIN}`, `${ROLES.TALLERISTA}`]}
                >
                  <TallerCreateSuccessScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/edicion-finalizada"
              element={<TallerEditSuccessScreen />}
            />
            <Route path="/mi-colectiva" element={<ColectivaScreen />} />
            <Route path="/nueva-amiga" element={<NewAmigaScreen />} />
            <Route
              path="/nueva-amiga-informacion"
              element={
                <PrivateRoute principalRol={[`${ROLES.ADMIN}`]}>
                  <NewAmigaInfoScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/nueva-amiga-colectiva"
              element={
                <PrivateRoute principalRol={[`${ROLES.ADMIN}`]}>
                  <NewAmigaColectivaScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/nueva-amiga-acceso"
              element={
                <PrivateRoute principalRol={[`${ROLES.ADMIN}`]}>
                  <NewAmigaAuthScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/nueva-amiga-finalizado"
              element={
                <PrivateRoute principalRol={[`${ROLES.ADMIN}`]}>
                  <NewAmigaSuccessScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/editar-usuario/:id"
              element={
                <PrivateRoute principalRol={[`${ROLES.ALL}`]}>
                  <EditUserScreen />
                </PrivateRoute>
              }
            />
            <Route
              path="/editar-usuario-finalizado/:id"
              element={
                <PrivateRoute principalRol={[`${ROLES.ALL}`]}>
                  <EditUserSuccessScreen />
                </PrivateRoute>
              }
            />
            <Route path="/editar-administrador" element={<EditAdminScreen />} />
            <Route
              path="/editar-administrador-finalizado"
              element={<EditAdminSuccessScreen />}
            />
            <Route path="/evaluaciones" element={<EvaluationAdminScreen />} />
            <Route path="/mujeres" element={<AllWomenScreen />} />
            <Route path="/equipo-de-las-amigas" element={<TeamScreen />} />
            <Route path="/coordinadoras" element={<CoordinadorasScreen />} />
            <Route path="/acompanantes" element={<AcompanantesScreen />} />
            <Route path="/talleristas" element={<TalleristasScreen />} />
            <Route path="/entregadoras" element={<EntregadorasScreen />} />
            <Route path="/resultado-de-busqueda" element={<SearchScreen />} />
            <Route path="/documentos" element={<DonationsScreen />} />
            <Route
              path="/mi-perfil"
              element={
                <PrivateRoute principalRol={[`${ROLES.ALL}`]}>
                  <OwnerDetailScreen />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NotFoundScreen />} /> {/* Ruta 404 */}
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </main>
  );
}

export default App;
