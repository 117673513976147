import React, {useState} from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {ROLES_DATA} from '../../../../utils/roles'

interface ModalAsignarRolComponentProps {
  show: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const ModalAsignarRolComponent: React.FC<ModalAsignarRolComponentProps> = ({ show, onHide, onSuccess }) => {
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('default');

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const isButtonDisabled = selectedOption === 'default';

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Evitar recarga de la página al enviar el formulario
    
    // Simulando el envío exitoso del formulario
    // Aquí debes agregar la lógica real para enviar el formulario al servidor
    // y establecer setFormSubmittedSuccessfully(true) en la devolución de llamada de éxito
    setFormSubmittedSuccessfully(true);
    onSuccess();
  };

  const handleModalClose = () => {
    onHide();

    // Si el formulario se envió con éxito, reiniciar el estado de formSubmittedSuccessfully
    if (formSubmittedSuccessfully) {
      setFormSubmittedSuccessfully(false);
    }
  };

  return (
    <>
      <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal--gray"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="text-center"><i className="icon icon--derivar-small"></i> Asignar Rol secundario</h2>
        </Modal.Title>
        <button type="button" className="btn-close" onClick={handleModalClose} aria-label="Close"></button>
      </Modal.Header>
      <Modal.Body>
        <h3 className="h4 text-center mb-2">Selecciona el Rol secundario a asignar</h3>
        <p className="text-20 text-center mb-5">Rol actual: <strong>Tallerista</strong></p>
        <form onSubmit={handleSubmit}>
          <div className="form-row pb-3">
            <label className="form-label label--icon">
              Rol <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Roles disponibles
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
                <select
                  className="form-select requiredField"
                  aria-label="Colectiva"
                  defaultValue={'default'}
                  name="colectiva"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="default" disabled>Selecciona una opción</option>
                  {ROLES_DATA.map((roles) =>
                     <option key={roles.id} value={roles.value}>
                     {roles.label}
                   </option>
                  )}
                </select>
          </div>
          <div className="form-row d-flex justify-content-center">
            <button
              type="submit"
              className="btn btn--type1 btn--290"
              disabled={isButtonDisabled}
            >Asignar Rol</button>
          </div>
        </form>
      </Modal.Body>
      
    </Modal>
    </>
  );
};

export default  ModalAsignarRolComponent;