import React from 'react';
import HeaderAdminShared from '../../../shared/Header/headerAdmin.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import ReactSearchAutocomplete from 'react-search-autocomplete/dist/components/ReactSearchAutocomplete';
import { ADMIN_DATA } from '../../../utils/adminUsers';
import AdminCardProps from '../../../components/Elements/AdminCard/adminCard.component';

interface Item {
  id: number;
  name: string;
}

const TeamScreen: React.FC = () => {

  const handleOnSearch = (string: string, results: Item[]) => {
    console.log(string, results);
  }

  const handleOnSelect = (item: Item) => {
    // the item selected
    console.log(item)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const handleOnHover = (result: Item) => {
    // the item hovered
    console.log(result)
  }

  const formatResult = (item: Item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }
  return (
    <>
      <HeaderAdminShared />
      <section className="module container">
        <div className="body-row row mb-4">
          <div className="col-12">
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <h1>Equipo de Las Amigas</h1>
              </div>
              <div className="col-12 mb-4">
                <a href="/detalle-acompanante" className="btn--back"><i className="icon icon--back"></i> Regresar</a>
              </div>
              <div className="col-12">
                <h2 className="h3">Busca entre Las Amigas</h2>
                <div className="form-search-complete">
                  <div className="autocomplete-search search--100">
                    <ReactSearchAutocomplete
                      items={ADMIN_DATA}
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      onFocus={handleOnFocus}
                      autoFocus
                      formatResult={formatResult}
                      placeholder="Buscar colectiva"
                      className="autocomplete-search__input"
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="row">
          <div className="mt-4 user-info-tab">
            <h4 className="text-20 text-regular text-purple800">Coordinadoras de Colectiva</h4>
            <hr className="mt-0 hr-pink"/>
            <div className="row">
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Rafaela"
                  role="Coordinadora"
                  url="/detalle-coordinadora"
                  title="h3"
                />
              </div>
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Rafaela"
                  role="Coordinadora"
                  url="/detalle-coordinadora"
                  title="h3"
                />
              </div>
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Rafaela"
                  role="Coordinadora"
                  url="/detalle-coordinadora"
                  title="h3"
                />
              </div>
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Rafaela"
                  role="Coordinadora"
                  url="/detalle-coordinadora"
                  title="h3"
                />
              </div>
              <div className="d-flex col-12 justify-content-center mt-3">
                <a href="/coordinadoras" className="btn btn--type2">Ver a todas las coordinadoras de colectiva</a>
              </div>
            </div>
          </div>
          <div className="mt-4 user-info-tab">
            <h4 className="text-20 text-regular text-purple800">Acompañantes</h4>
            <hr className="mt-0 hr-pink"/>
            <div className="row">
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Sara"
                  role="Acompañante"
                  url="/detalle-acompanante"
                  title="h3"
                />
              </div>
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Sara"
                  role="Acompañante"
                  url="/detalle-acompanante"
                  title="h3"
                />
              </div>
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Sara"
                  role="Acompañante"
                  url="/detalle-acompanante"
                  title="h3"
                />
              </div>
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Sara"
                  role="Acompañante"
                  url="/detalle-acompanante"
                  title="h3"
                />
              </div>
              <div className="d-flex col-12 justify-content-center mt-3">
                <a href="/acompanantes" className="btn btn--type2">Ver a todas las acompañantes</a>
              </div>
            </div>
          </div>
          <div className="mt-4 user-info-tab">
            <h4 className="text-20 text-regular text-purple800">Talleristas</h4>
            <hr className="mt-0 hr-pink"/>
            <div className="row">
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Sara"
                  role="Talleristas"
                  url="/detalle-tallerista"
                  title="h3"
                />
              </div>
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Sara"
                  role="Talleristas"
                  url="/detalle-tallerista"
                  title="h3"
                />
              </div>
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Sara"
                  role="Talleristas"
                  url="/detalle-tallerista"
                  title="h3"
                />
              </div>
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Sara"
                  role="Talleristas"
                  url="/detalle-tallerista"
                  title="h3"
                />
              </div>
              <div className="d-flex col-12 justify-content-center mt-3">
                <a href="/talleristas" className="btn btn--type2">Ver a todas las talleristas</a>
              </div>
            </div>
          </div>
          <div className="mt-4 user-info-tab">
            <h4 className="text-20 text-regular text-purple800">Entregadoras</h4>
            <hr className="mt-0 hr-pink"/>
            <div className="row">
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Sara"
                  role="Entregadoras"
                  url="/detalle-entregadora"
                  title="h3"
                />
              </div>
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Sara"
                  role="Entregadoras"
                  url="/detalle-entregadora"
                  title="h3"
                />
              </div>
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Sara"
                  role="Entregadoras"
                  url="/detalle-entregadora"
                  title="h3"
                />
              </div>
              <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                <AdminCardProps
                  name="Sara"
                  role="Entregadoras"
                  url="/detalle-entregadora"
                  title="h3"
                />
              </div>
              <div className="d-flex col-12 justify-content-center mt-3">
                <a href="/entregadoras" className="btn btn--type2">Ver a todas las entregadoras</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default TeamScreen;