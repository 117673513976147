export const COLECTIVAS_DATA = [
  {
    title: "Valparaíso",
    organizer: "Rafaela",
    number: 10000,
    currency: 1100,
    url: "/mi-colectiva"
  },
  {
    title: "Petorca",
    organizer: "Rafaela",
    number: 920,
    currency: 1100,
    url: "/mi-colectiva"
  },
  {
    title: "Quillota",
    organizer: "Rafaela",
    number: 920,
    currency: 1100,
    url: "/mi-colectiva"
  },
 
]

