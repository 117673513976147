export const USERS_DATA = [
  {
    "name": "Paulina Pimentel",
    "id": "273923",
    "registrationDate": "18/01/2023 (24 horas)",
    "weeks": "6 + 3 días",
    "origin": "OLA",
    "cause": "2",
    "commune": "Valparaíso",
    "derivationReasons": [
      "Necesita ayuda adicional",
      "No es mi área de especialidad"
    ],
    "referringProfessional": "Atención psicológica",
    "detailUrl": "/detalle-usuario"
  }  
]

