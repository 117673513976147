import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { IUser, UserStatus } from '../../../../types/userInterface';

interface ModalDisabledComponentProps {
  user: Partial<IUser>;
  show: boolean;
  onHide: () => void;
  onSuccess: (user: Partial<IUser>) => void;
}

const ModalDisabledComponent: React.FC<ModalDisabledComponentProps> = ({
  user,
  show,
  onHide,
  onSuccess,
}) => {
  const [personalData, setPersonalData] = useState<Partial<IUser>>({});
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] =
    useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onHide();

    // Simulando el envío exitoso del formulario
    // Aquí debes agregar la lógica real para enviar el formulario al servidor
    // y establecer setFormSubmittedSuccessfully(true) en la devolución de llamada de éxito
    setFormSubmittedSuccessfully(true);
    onSuccess({
      _id: personalData._id,
      status: UserStatus.INACTIVE,
    });
  };

  const handleModalClose = () => {
    onHide();

    // Si el formulario se envió con éxito, reiniciar el estado de formSubmittedSuccessfully
    if (formSubmittedSuccessfully) {
      setFormSubmittedSuccessfully(false);
    }
  };

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (user) {
      setPersonalData(user);
      setIsChecked(user.status === UserStatus.INACTIVE);
    }
  }, [user]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal--gray"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="text-center">
              <i className="icon icon--disabled"></i> Desactivar temporalmente
            </h2>
          </Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={handleModalClose}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <h3 className="h4 text-center mb-5">
            ¿Deseas desactivar temporalmente esta cuenta?
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="form-row pb-3 d-flex justify-content-center">
              <Form.Check // prettier-ignore
                type="switch"
                id="disabled"
                label="Desactivar cuenta"
                checked={isChecked}
                onChange={handleSwitchChange}
              />
            </div>
            <p className="text-center mb-5">
              Necesitamos tu confirmación, recuerda que puedes volver a activar
              esta cuenta cuando lo desees.
            </p>
            <div className="form-row d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn--type1 btn--290"
                disabled={!isChecked}
              >
                Confirmar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalDisabledComponent;
