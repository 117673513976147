import React from 'react';
import HeaderAdminShared from '../../../shared/Header/headerAdmin.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import NewColectivaComponent from '../../../components/Auth/Colectiva/newColectiva.component';

const NewColectivaScreen: React.FC = () => {
  return (
    <>
      <HeaderAdminShared />
        <section className="module container">
          <div className="body-row row">
            <div className="col-12">
            
              <div className="auth-row row">
                <div className="col-12 mb-4">
                  <a href="/colectivas" className="btn--back"><i className="icon icon--back"></i> Regresar</a>
                </div>
                <div className="col-md-5 col-12">
                  <h1 className="mb-3">Registrar nueva colectiva</h1>
                  <p>Llena el formulario para poder registrar la colectiva</p>
                  <NewColectivaComponent />
                </div>
                <aside className="auto-row__right col-md-7 col-12">
                  <figure className="image-400">
                    <img src="/assets/images/illustrations/remember.png" alt="Women" />
                  </figure>
                </aside>
              </div>
            </div>
          </div>
        </section>
       <FooterShared />
    </>
  );
};

export default NewColectivaScreen;