import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import TalleresComponent from '../../Talleres/talleres.component';
import { getSession } from '../../../../utils/helpers';
import { searchWorkshops } from '../../../../api/workshopApi';
import { IWorkshop } from '../../../../types/workshopInterface';
import { IUser, UserSteps } from '../../../../types/userInterface';
import { updateUser } from '../../../../api/usersApi';

interface ModalTallerProps {
  show: boolean;
  onHide: () => void;
  headerTitle?: string;
  headerSubtitle?: string;
  showSubmitButton?: boolean;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  user: Partial<IUser>;
}
const ModalTallerComponents: React.FC<ModalTallerProps> = ({
  show,
  onHide,
  headerTitle = 'Derivar a otro taller', // Valor por defecto
  headerSubtitle = 'Selecciona entre los talleres disponibles', // Valor por defecto
  showSubmitButton = false, // Muestra el botón por defecto
  setLoading,
  user,
}) => {
  const [workshops, setWorkshops] = useState<IWorkshop[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const handleCheckboxChange = (id: string, isSelected: boolean) => {
    const currentSelected = [...selected];
    if (isSelected) {
      currentSelected.push(id);
    } else {
      const index = currentSelected.indexOf(id);
      if (index > -1) {
        currentSelected.splice(index, 1);
      }
    }
    console.log(currentSelected);
    setSelected([...currentSelected]);
  };

  const fetchWorkshops = async () => {
    try {
      if (setLoading) {
        setLoading(true);
      }
      const session = getSession();
      const response = await searchWorkshops(
        session?.token || '',
        {
          page: 1,
          limit: 50,
        },
        {
          isFull: false,
          status: 'active',
        }
      );
      setWorkshops(response.docs);
    } catch (error) {
      setError('Ocurrió un error al obtener los talleres');
    } finally {
      if (setLoading) {
        setLoading(false);
      }
    }
  };

  const handleWorkshopSelected = async () => {
    try {
      setLoading && setLoading(true);
      onHide();
      const session = getSession();
      const response = await updateUser(
        user._id || '',
        {
          possibleWorkshops: selected,
          step: UserSteps.TALLER,
        },
        session?.token || ''
      );
      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Talleres asignados correctamente',
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      setError('Ocurrió un error al asignar los talleres');
    } finally {
      setLoading && setLoading(false);
    }
  };

  useEffect(() => {
    if (user._id) {
      fetchWorkshops();
    }
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="col-12">
          <div className="col-12 d-flex justify-content-center mb-4">
            <i className="icon icon--derivar"></i>
          </div>
          <h2 className="text-40 text-center text-500 pb-2">{headerTitle}</h2>
          <p className="text-24 text-center">{headerSubtitle}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={`row justify-content-center`}>
          {workshops.length > 0 ? (
            workshops.map((workshop, index) => {
              return (
                <TalleresComponent
                  mode={'seleccionar'}
                  cols="col-xl-3"
                  key={index}
                  workshop={workshop}
                  handleCheckboxChange={handleCheckboxChange}
                  selected={selected.includes(workshop._id)}
                />
              );
            })
          ) : (
            <div className="col-12 d-flex justify-content-center mb-3">
              <p className="text-16 text-center">
                No hay talleres disponibles.
              </p>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {showSubmitButton && (
          <div className="col-12 d-flex justify-content-center mb-3">
            <button
              type="button"
              className="btn btn--type1 btn--370"
              onClick={handleWorkshopSelected}
              disabled={selected.length === 0} // Habilitado si hay al menos un taller seleccionado
            >
              Enviar selección de talleres
            </button>
          </div>
        )}
        <div className="col-12 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn--type3 btn--370"
            onClick={onHide}
          >
            Cerrar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTallerComponents;
