import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import ModalNotesComponents from '../Modal/ModalNotes/modalNotes.component';
import ModalNotesComponentsSuccess from '../Modal/ModalNotes/modalNotesSuccess.component';
import { IUser, IUserNote } from '../../../types/userInterface';
import { IAdmin } from '../../../types/adminInterface';

interface NotesComponentProps {
  user: Partial<IUser>;
  admin: Partial<IAdmin>;
  handleAddNote?: (note: IUserNote) => void;
}

const NotesComponent: React.FC<NotesComponentProps> = ({
  user,
  admin,
  handleAddNote,
}) => {
  const [notes, setNotes] = useState<IUserNote[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowSuccess, setModalShowSuccess] = useState(false);

  /* const handleAddNote = () => {
    setModalShow(false);
    setTimeout(() => {
      setModalShowSuccess(true);
    }, 300);
  }; */

  const handleBackNote = () => {
    setModalShowSuccess(false);
    setTimeout(() => {
      setModalShow(true);
    }, 300);
  };

  useEffect(() => {
    const notes = user.notes || [];
    setNotes(notes.reverse());
  }, [user]);

  return (
    <>
      <ModalNotesComponents
        show={modalShow}
        onHide={() => setModalShow(false)}
        onAddNote={(note) => {
          handleAddNote && handleAddNote(note);
          setModalShow(false);
          setTimeout(() => {
            setModalShowSuccess(true);
          }, 300);
        }}
        admin={admin}
      />
      <ModalNotesComponentsSuccess
        show={modalShowSuccess}
        onHide={() => setModalShowSuccess(false)}
        onBackNote={handleBackNote}
      />
      <div className="note-wrapp">
        {notes.map((note, index) => (
          <div className="note-row" key={index}>
            <p>
              {(note.adminId as IAdmin).username} -{' '}
              {format(new Date(note.createdAt), 'dd / MMMM / yyyy - HH:mm') +
                ` hrs.`}
            </p>
            <div className="note-row__body">
              <p>{note.note}</p>
            </div>
          </div>
        ))}
        <button
          className="btn btn--type2 btn--100"
          onClick={() => setModalShow(true)}
        >
          <span className="material-icons-outlined">add</span> Agregar nota
        </button>
      </div>
    </>
  );
};

export default NotesComponent;
