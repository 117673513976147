import React, {useState, useEffect} from 'react';
import DataTable, { TableColumn }  from 'react-data-table-component';
import CustomPagination from '../Paginator/paginator.component'
import { Accordion } from 'react-bootstrap';

interface DataRow {
  id: number;
  ficha: string;
  name: string;
  weeks: string;
  derivada: string;
  folleto: number;
  status: string;
  time: string;
  url: string;
  isNew: boolean;
}

const AcompananteTableComponent: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1200);
  const rowsPerPage = 10;
  const [rows, setRows] = useState<DataRow[]>([
    {
      id: 1,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      folleto: 10,
      status: 'registrada',
      time: '11:10 am 11/12/23',
      url: '/detalle-usuario',
      isNew: true,
    },
    {
      id: 2,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      folleto: 10,
      status: 'en-espera',
      time: '11:10 am 11/12/23',
      url: '/detalle-usuario',
      isNew: true,
    },
    {
      id: 3,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      folleto: 10,
      status: 'en-taller',
      time: '11:10 am 11/12/23',
      url: '/detalle-usuario',
      isNew: true,
    },
    {
      id: 4,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      folleto: 10,
      status: 'logrado',
      time: '11:10 am 11/12/23',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 5,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      folleto: 10,
      status: 'seguimiento',
      time: '11:10 am 11/12/23',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 6,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      folleto: 10,
      status: 'seguimiento',
      time: '11:10 am 11/12/23',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 7,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      folleto: 10,
      status: 'seguimiento',
      time: '11:10 am 11/12/23',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 8,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      folleto: 10,
      status: 'seguimiento',
      time: '11:10 am 11/12/23',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 9,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      folleto: 10,
      status: 'seguimiento',
      time: '11:10 am 11/12/23',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 10,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      folleto: 10,
      status: 'seguimiento',
      time: '11:10 am 11/12/23',
      url: '/detalle-usuario',
      isNew: false,
    },
    {
      id: 11,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      folleto: 10,
      status: 'seguimiento',
      time: '11:10 am 11/12/23',
      url: '/detalle-usuario',
      isNew: false,
    },

  ]);

  const handleNameClick = (id: number) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, isNew: false };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: 'No. Ficha',
      selector: (row: DataRow) => row.ficha,
    },
    {
      name: 'Nombre',
      selector: (row: DataRow) => row.name,
      format: (row: DataRow) => {
        return (
          <a href={row.url} 
             className={`text-purple500 text-uppercase ${row.isNew ? 'new' : ''}`}
             onClick={() => handleNameClick(row.id)}>
            <strong>{row.name}</strong>
          </a>
        );
      }
    },
    {
      name: 'Semanas',
      selector: (row: DataRow) => row.weeks,
    },
    {
      name: 'Derivada de',
      selector: (row: DataRow) => row.derivada,
    },
    {
      name: 'Folleto',
      selector: (row: DataRow) => row.folleto,
      format: (row: DataRow) => {
        return (
          <span className="data-table__number">{row.folleto}</span>
        );
      }
    },
    {
      name: 'Estado',
      selector: (row: DataRow) => row.status,
      format: (row: DataRow) => {
        if (row.status === 'registrada') {
          return (
            <span className="badge badge--status badge--status-success">Registrada</span>
          );
        } else if (row.status === 'en-espera') {
          return (
            <span className="badge badge--status badge--status-success">En espera</span>
          );
        } else if (row.status === 'en-taller') {
          return (
            <span className="badge badge--status badge--status-taller">En taller</span>
          );
        } else if (row.status === 'logrado') {
          return (
            <span className="badge badge--status badge--status-done">Logrado</span>
          );
        } else if (row.status === 'seguimiento') {
          return (
            <span className="badge badge--status badge--status-info">Seguimiento</span>
          );
        } else if (row.status === 'no-logrado') {
          return (
            <span className="badge badge--status badge--status-info">No logrado</span>
          );
        } else {
          return (
            <span className="badge badge--status badge--status-info">{row.status}</span>
          );
        }
       
      }
    },
    {
      name: 'Hora de registro',
      selector: (row: DataRow) => row.time,
      format: (row: DataRow) => {
        // Dividir la cadena en la hora y la fecha
        const parts = row.time.split(' ');
        const time = `${parts[0]} ${parts[1]}`; // Hora (11:10 am)
        const date = parts.slice(2).join(' '); // Fecha (11/12/23)

        return (
          <div className="data-table__time">
            <span style={{ marginRight: 8 }}>{time}</span>
            <span>{date}</span>
          </div>
        );
      }
    },
  ];
  
  const conditionalRowStyles = [
    {
      when: (row: DataRow) => row.isNew,
      style: {
        backgroundColor: '#FCEEF2',
      }
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const formatStatus = (status: string) => {
    switch (status) {
      case 'registrada':
        return <span className="badge badge--status badge--status-success">Registrada</span>;
      case 'en-espera':
        return <span className="badge badge--status badge--status-success">En espera</span>;
      case 'en-taller':
        return <span className="badge badge--status badge--status-taller">En taller</span>;
      case 'done':
        return <span className="badge badge--status badge--status-done">Logrado</span>;
      case 'seguimiento':
        return <span className="badge badge--status badge--status-info">Seguimiento</span>;
      case 'no-logrado':
        return <span className="badge badge--status badge--status-info">No logrado</span>;
      default:
        return <span className="badge badge--status badge--status-info">{status}</span>;
    }
  };

  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="data-table">

    {!isMobileView ? (
        <DataTable
            columns={columns}
            data={rows}
            conditionalRowStyles={conditionalRowStyles}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationComponent={props => {
              const handleChangePage = (page: number) => {
                console.log('Changing page to', page);
                // Asume que props.onChangePage realmente espera un solo argumento.
                // Esto es para diagnóstico; revisa la consola para ver qué se registra cuando cambias de página.
                (props.onChangePage as (page: number) => void)(page);

              };
            
              return (
                <div className="data-paginator">
                  <CustomPagination
                    rowsPerPage={props.rowsPerPage} // eslint-disable-next-line react/prop-types
                    rowCount={props.rowCount} // eslint-disable-next-line react/prop-types
                    onChangePage={handleChangePage}
                    currentPage={currentPage}
                  />
                </div>
              );
            }}
            
            onChangePage={page => setCurrentPage(page)}
            paginationPerPage={rowsPerPage}
            paginationTotalRows={rows.length}
        />
      
    ): (
    <section className="data-table-mobile">
          <div className="data-table-mobile__header">
            <p><strong>Nombre</strong></p>
          </div>
          <Accordion className="data-table-mobile__accordion">
            {rows.map((row, index) => (
              
               (() => {
                  // Dividir la cadena en la hora y la fecha
                  const parts = row.time.split(' ');
                  const time = `${parts[0]} ${parts[1]}`; // Hora (11:10 am)
                  const date = parts.slice(2).join(' '); // Fecha (11/12/23)
                return(
                  <Accordion.Item eventKey={String(index)} key={row.id}>
                  <Accordion.Header>{row.name}</Accordion.Header>
                  <Accordion.Body>
                    <table>
                      <tr>
                        <td><strong>No. Ficha:</strong></td>
                        <td className="text-center">{row.ficha}</td>
                      </tr>
                      <tr>
                        <td><strong>Semanas:</strong></td>
                        <td className="text-center">{row.weeks}</td>
                      </tr>
                      <tr>
                        <td><strong>Derivada de:</strong></td>
                        <td className="text-center">{row.derivada}</td>
                      </tr>
                     
                      <tr>
                        <td><strong>Folleto:</strong></td>
                        <td className="text-center"><span className="data-table__number">{row.folleto}</span></td>
                      </tr>
                      <tr>
                        <td><strong>Estado:</strong></td>
                        <td className="text-center">{formatStatus(row.status)}</td>
                      </tr>
                      <tr>
                        <td><strong>Hora de registro:</strong></td>
                        <td className="text-center">
                          <div className="data-table__time">
                              <span style={{ marginRight: 8 }}>{time}</span>
                              <span>{date}</span>
                            </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="text-center">
                          <a href="/detalle-usuario" className="btn btn--type2">
                            <strong>Ver ficha</strong>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
                )
              })()
            ))}
          </Accordion>
        </section>

    )}
     
    </div>
  );
};

export default AcompananteTableComponent;
