import React from 'react';
import { IWorkshop } from '../../../types/workshopInterface';
import { format } from 'date-fns';

interface Props {
  workshop: Partial<IWorkshop>;
}

const TallerItemComponent: React.FC<Props> = ({ workshop }) => {
  const getStockList = () => {
    const stockList = [1, 2, 3, 4, 5];
    const takedPlaces = workshop.assignedUsers?.length || 0;
    const workshopSize = workshop.availablePlaces || 0;
    const stock = workshopSize / stockList.length;
    return stockList.map((item, i) => {
      const isTaked = takedPlaces >= item * stock;
      return (
        <li key={i}>
          <span
            className={`icon material-icons-outlined ${
              isTaked ? 'stock--enabled' : 'stock--disabled'
            }`}
          >
            airline_seat_recline_normal
          </span>
        </li>
      );
    });
  };

  return (
    <div className="item-workshop__col col-sm-6 col-12">
      <div className="item-workshop">
        <figure className="item-workshop__image">
          <p
            className={`badge badge-label badge--${
              workshop.workshopType === 'En línea' ? 'success' : 'info'
            }`}
          >
            <span className="icon material-icons-outlined">verified</span>
            <span>
              <small>{`${workshop.workshopType || ''}`}</small>
            </span>
          </p>
          <img src="/assets/images/illustrations/remember.png" alt="Taller" />
        </figure>
        <article>
          <p className="badge badge-label badge--info">
            <span className="icon material-icons-outlined">
              airline_seat_recline_normal
            </span>
            <span>
              {`${
                (workshop.availablePlaces || 0) -
                (workshop.assignedUsers?.length || 0)
              }`}{' '}
              CUPOS DISPONIBLES
            </span>
          </p>
          <ul className="stock-list">
            {getStockList()}
            <li>{`${workshop.availablePlaces || ''}`} CUPOS</li>
          </ul>
          {workshop.workshopDate && (
            <p className="text-gray600 mb-0">
              {format(
                new Date(workshop.workshopDate || ''),
                'EEEE dd MMMM, yyyy'
              )}
            </p>
          )}
          <p className="text-icon">
            <i className="icon icon--clock"></i>
            {`${workshop.workshopSchedule || ''}`}
          </p>
        </article>
      </div>
    </div>
  );
};

export default TallerItemComponent;
