import React from 'react';

type UserCardProps = {
  name: string;
  id: string;
  registrationDate: string;
  weeks: string;
  origin: string;
  cause: string;
  commune: string;
  derivationReasons: string[];
  referringProfessional: string;
  detailUrl: string;
};


const UserCardComponent: React.FC<UserCardProps> = ({
  name,
  id,
  registrationDate,
  weeks,
  origin,
  cause,
  commune,
  derivationReasons,
  referringProfessional,
  detailUrl,
}) => {
  return (
    <>
       <div className="user-card">
            <div className="chatbox-action__user">
              <figure>
                <img src="/assets/images/icons/user-pic.svg" alt={name} />
              </figure>
              <article>
                <h4>{name}</h4>
                <p className="text-20"><strong>No. {id}</strong></p>
              </article>
            </div>
            <table className="table-user-data">
              <tbody>
                <tr>
                  <td><p className="data-info"><i className="icon icon--message-clip"></i> Registrada:</p></td>
                  <td>{registrationDate}</td>
                </tr>
                <tr>
                  <td><p className="data-info"><i className="icon icon--calendar-clip"></i> Semanas:</p></td>
                  <td>{weeks}</td>
                </tr>
                <tr>
                  <td><p className="data-info"><i className="icon icon--edit-clip"></i> Derivada de:</p></td>
                  <td>{origin}</td>
                </tr>
                <tr>
                  <td><p className="data-info"><i className="icon icon--edit-clip"></i> Causal:</p></td>
                  <td>{cause}</td>
                </tr>
                <tr>
                  <td><p className="data-info"><i className="icon icon--edit-clip"></i> Comuna:</p></td>
                  <td>{commune}</td>
                </tr>
              </tbody>
            </table>
            <div className="user-card__detail">
              {derivationReasons.map((reason, index) => (
                <article key={index} className="mb-3">
                  <p className="text-gray600 mb-2"><strong>Motivos de derivación</strong></p>
                  <p className="text-500 mb-2">{reason}</p>
                </article>
              ))}
              <article className="mb-3">
                <p className="text-gray600 mb-2"><strong>Profesional que deriva</strong></p>
                <p className="text-500 mb-2">{referringProfessional}</p>
              </article>
            </div>
            <div className="user-card__bottom d-flex justify-content-center mt-4">
              <a href={detailUrl} className="btn btn--type1">Ver ficha</a>
            </div>
          </div>
    </>
  );
};

export default UserCardComponent;