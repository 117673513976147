import React, {useState, useEffect} from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';

const TallerEditComponent = () => {
  const navigate = useNavigate();
  const [modalidad, setModalidad] = useState('');
  const [fecha, setFecha] = useState('');
  const [lugar, setLugar] = useState('');
  const [horario, setHorario] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate('/edicion-finalizada');
  };

   // Handler para el cambio de modalidad
   const handleModalidadChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setModalidad(event.target.value);
  };

  // Actualiza el estado cuando cambian los inputs
  const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { id, value } = event.target;
    switch (id) {
      case 'date':
        setFecha(value);
        break;
      case 'place':
        setLugar(value);
        break;
      case 'time':
        if (event.target.tagName === 'SELECT') {
          setModalidad(value); // Este debería ser para la modalidad
        } else {
          setHorario(value); // Este para el horario
        }
        break;
      default:
        break;
    }
  };

  // Revisa si los campos requeridos están llenos para habilitar el botón
  const canSubmit = () => {
    const isFechaValida = fecha !== '';
    const isLugarValido = lugar !== 'default';
    const isHorarioValido = modalidad !== 'telefono' ? horario !== 'default' : true;
    const isModalidadValida = modalidad !== 'default';
  
    return isFechaValida && isLugarValido && isHorarioValido && isModalidadValida;
  };
  
  
  

  // Estado para manejar si el botón debe estar deshabilitado
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    setIsButtonDisabled(!canSubmit());
  }, [fecha, lugar, horario, modalidad]);

  return (
    <>
       <div className="form-block">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <label htmlFor="date" className={`form-label label--icon`}>
                Fecha del taller <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Fecha del taller 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <input
                type="date"
                className={`form-control inputRequired`}
                id="date"
                placeholder="Ingresa tu correo electrónico"
                value={fecha}
                onChange={handleInputChange}
              />
            
            </div>
            <div className="form-row">
              <label htmlFor="place" className={`form-label label--icon`}>
                Lugares disponibles
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Lugares disponibles 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <select
                id="place"
                className="form-control form-select inputRequired"
                disabled={modalidad === 'telefono'}
                value={lugar}
                onChange={handleInputChange}
                defaultValue={'default'}
              >
                <option  value="default">Elige una opción</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
             
              </select>
            
            </div>
            <div className="form-row">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="time" className={`form-label label--icon`}>
                    Horario
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip >
                          Horario 
                        </Tooltip>
                      }
                    >
                      <span className="material-icons-outlined icon">help_outline</span>
                    </OverlayTrigger>
                  </label>
                  <select
                    id="time"
                    className="form-control form-select inputRequired"
                    value={horario}
                    onChange={handleInputChange}
                    defaultValue={'default'}
                  >
                    <option  value="default">Elige una opción</option>
                    <option value="17:00 - 19:00 Hrs.">17:00 - 19:00 Hrs.</option>
                    <option value="19:00 - 20:00 Hrs.">19:00 - 20:00 Hrs.</option>
                    <option value="20:00 - 21:00 Hrs.">20:00 - 21:00 Hrs.</option>
                 
                  </select>
            
                </div>
                <div className="col-6">
                  <label htmlFor="time" className={`form-label label--icon`}>
                    Modalidad
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip >
                          Modalidad 
                        </Tooltip>
                      }
                    >
                      <span className="material-icons-outlined icon">help_outline</span>
                    </OverlayTrigger>
                  </label>
                  <select
                    id="time"
                    className="form-control form-select inputRequired"
                    value={modalidad}
                    onChange={handleModalidadChange}
                    defaultValue={'default'}
                  >
                  <option  value="default">Elige una opción</option>
                    <option value="en-linea">En línea</option>

                    <option value="telefono">Teléfono</option>
                    <option value="presencial">Presencial</option>
                 
                  </select>
            
                </div>
              </div>
            </div>
            <div className="form-row form--last">
              <button type="submit" className="btn btn--type1" disabled={isButtonDisabled}>Actualizar datos</button>
            </div>
           
            <div className="form-row form--last">
              <a href="/talleres" className="btn btn--type2">Cancelar edición</a>
            </div>
          </form>
        </div>
    </>
  );
}

export default TallerEditComponent;