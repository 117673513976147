export const TALLERES_COMING_DATA = [
  {
    title: "No estás sola",
    organizer: "Rafaela",
    date: "27 de jun 2023",
    url: "/talleres-asistentes"
  },
  {
    title: "No estás sola",
    organizer: "Rafaela",
    date: "27 de jun 2023",
    url: "/talleres-asistentes"
  },
  {
    title: "No estás sola",
    organizer: "Rafaela",
    date: "27 de jun 2023",
    url: "/talleres-asistentes"
  },
]

