import React from 'react';
import HeaderAdminShared from '../../../shared/Header/headerAdmin.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import Accordion from 'react-bootstrap/Accordion';
import { useState } from 'react';
import Calendar from 'react-calendar';
import TagsManagerComponent from '../../../components/Forms/tagsManager/tagsManager.component';
import WomenTableComponent from '../../../components/Elements/DataTable/womenTable.component copy';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const AllWomenScreen: React.FC = () => {
  const [value, setValue] = useState<Value>(new Date());

  const handleDateChange = (newValue: Value) => {
    setValue(newValue);
  };

  // Define los tipos para los parámetros de la función tileDisabled
  const tileDisabled = ({ date, view }: { date: Date; view: string }) => {
    const currentYear = new Date().getFullYear();
    if (view === 'year') {
      return date.getFullYear() < currentYear - 1;
    }
    return false;
  };
  return (
    <>
      <HeaderAdminShared />
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom row">
            <div className="col-12 mb-3">
              <a href="/inicio-administrador" className="btn--back">
                <i className="icon icon--back"></i> Regresar
              </a>
            </div>
            <div className="title-section module-bottom40 col-12">
              <h1 className="h2">Registro de Mujeres</h1>
            </div>
            <aside className="dashboard-aside col-md-3 col-12">
              <div className="dashboard-aside__inner">
                <h4 className="mb-4">Buscar</h4>
                <div className="form-row form--search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar"
                  />
                  <i className="icon icon--search-bar"></i>
                </div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Búsqueda avanzada </Accordion.Header>
                    <Accordion.Body>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Calendarios
                        </label>
                        <Accordion className="accordion-inner">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              Selecciona una fecha{' '}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Calendar
                                onChange={handleDateChange}
                                value={value}
                                tileDisabled={tileDisabled}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Etiquetas
                        </label>
                        <TagsManagerComponent admin={{}} user={{}} />
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">Roles</label>
                        <select
                          className="form-select form--small"
                          aria-label="Roles"
                          defaultValue={'default'}
                          name="rol"
                        >
                          <option value="default" disabled>
                            Selecciona un rol
                          </option>
                          <option value="rol-1">Rol 1</option>
                          <option value="rol-2">Rol 2</option>
                          <option value="rol-3">Rol 3</option>
                        </select>
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Estado
                        </label>
                        <select
                          className="form-select form--small"
                          aria-label="Estado"
                          defaultValue={'default'}
                          name="estado"
                        >
                          <option value="default" disabled>
                            Selecciona un estado
                          </option>
                          <option value="Registra">Registrada</option>
                          <option value="En taller">En taller</option>
                          <option value="Logrado">Logrado</option>
                          <option value="Seguimiento">Seguimiento</option>
                          <option value="En espera">En espera</option>
                        </select>
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Colectiva
                        </label>
                        <select
                          className="form-select form--small"
                          aria-label="Colectiva"
                          defaultValue={'default'}
                          name="colectiva"
                        >
                          <option value="default" disabled>
                            Selecciona una colectiva
                          </option>
                          <option value="colectiva-1">Colectiva 1</option>
                          <option value="colectiva-2">Colectiva 2</option>
                        </select>
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Taller
                        </label>
                        <select
                          className="form-select form--small"
                          aria-label="Taller"
                          defaultValue={'default'}
                          name="taller"
                        >
                          <option value="default" disabled>
                            Selecciona un taller
                          </option>
                          <option value="taller-1">Taller 1</option>
                          <option value="taller-2">Taller 2</option>
                        </select>
                      </div>
                      <div className="form-row">
                        <button
                          type="button"
                          className="btn btn--type1 btn--100"
                        >
                          Buscar
                        </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </aside>
            <div className="dashboard-info col-md-9 col-12">
              <WomenTableComponent />
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default AllWomenScreen;
