import React, { useState, useEffect } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { IWorkshop } from '../../../types/workshopInterface';
import LoaderComponent from '../../Loader/loader.component';
import {
  createWorkshop,
  getWorkshop,
  updateWorkshop,
} from '../../../api/workshopApi';
import { getSession } from '../../../utils/helpers';
import { IAdmin } from '../../../types/adminInterface';
import { publishMessage } from '../../../api/chatApi';

const TallerCreateComponent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [workshop, setWorkshop] = useState<Partial<IWorkshop>>({});
  const [isReschedule, setIsReschedule] = useState(false);

  const { id } = useParams<{ id: string }>();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchWorkshop = async (workshopId: string) => {
    try {
      setLoading(true);
      if (!workshopId) {
        throw new Error('No se pudo obtener el id del taller');
      }
      const session = getSession();
      const response = await getWorkshop(workshopId, session?.token || '');
      setWorkshop(response);
    } catch (error) {
      setError('Ocurrió un error al obtener los datos del taller');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (
    id: string,
    session: IAdmin,
    setNotification: boolean
  ) => {
    setLoading(true);
    try {
      const result = await updateWorkshop(
        id,
        {
          adminId: session?._id || '',
          status: setNotification ? 'rescheduled' : 'active',
        },
        session?.token || ''
      );
      if (setNotification) {
        await publishMessage(
          {
            sender: session._id || '',
            senderName: session.username || '',
            message: `Tu taller ha sido reagendado a la fecha ${workshop.workshopTextDate}`,
            roomId: workshop.chatRoomId || '',
            transactionId: Math.floor(Math.random() * 1000000),
          },
          session?.token || ''
        );
      }
      if (result._id) {
        navigate(`/confirmar-taller/${result._id}`);
      } else {
        setError('Ocurrió un error al crear el taller');
      }
    } catch (error) {
      setError('Ocurrió un error al actualizar el taller');
    } finally {
      setLoading(false);
    }
  };

  const checkAndUpdate = async () => {
    if (isReschedule) {
      return Swal.fire({
        title: '¿Estás segura de reagendar este taller?',
        text: `
          Una vez que cambies la fecha, las mujeres inscritas recibirán la notificación 
          en el chat y es probable que algunas de ellas no puedan asistir y tengan que 
          cambiar de fecha.
        `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, actualizar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await handleUpdate(id || '', getSession() as IAdmin, true);
        }
      });
    }
    return handleUpdate(id || '', getSession() as IAdmin, false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const session = getSession();
      if (id && session) {
        await checkAndUpdate();
      } else {
        setLoading(true);
        const result = await createWorkshop(
          {
            ...workshop,
            adminId: session?._id || '',
            status: 'pending',
          },
          session?.token || ''
        );
        if (result._id) {
          navigate(`/confirmar-taller/${result._id}`);
        } else {
          setError('Ocurrió un error al crear el taller');
        }
      }
    } catch (error) {
      setError('Ocurrió un error al crear el taller');
    } finally {
      setLoading(false);
    }
  };

  const canSubmit = () => {
    const {
      name,
      availablePlaces,
      workshopDate,
      workshopSchedule,
      workshopType,
    } = workshop;
    return (
      name &&
      availablePlaces &&
      workshopDate &&
      workshopSchedule &&
      workshopType
    );
  };

  const getWorkshopDate = (workshop: Partial<IWorkshop>) => {
    if (workshop.workshopDate instanceof Date) {
      return format(workshop.workshopDate, 'yyyy-MM-dd');
    }
    if (workshop.workshopDate) {
      return format(new Date(workshop.workshopDate), 'yyyy-MM-dd');
    }
    return '';
  };

  useEffect(() => {
    setIsButtonDisabled(!canSubmit());
  }, [workshop]);

  useEffect(() => {
    if (id) {
      fetchWorkshop(id);
    }
  }, [id]);

  return (
    <>
      <div className="form-block">
        {loading && <LoaderComponent />}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label htmlFor="name" className={`form-label label--icon`}>
              Nombre del taller <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Nombre del taller</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="text"
              className={`form-control inputRequired`}
              id="name"
              name="name"
              placeholder="Ingresa el nombre del taller"
              value={workshop.name || ''}
              onChange={(e) =>
                setWorkshop((prevWorkshop) => ({
                  ...prevWorkshop,
                  name: e.target.value,
                }))
              }
            />
          </div>
          <div className="form-row">
            <label htmlFor="date" className={`form-label label--icon`}>
              Fecha del taller <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Fecha del taller</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="date"
              className={`form-control inputRequired`}
              placeholder="Ingresa tu correo electrónico"
              id="date"
              name="workshopDate"
              value={getWorkshopDate(workshop)}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length === 10) {
                  const splittedDate = value.split('-');
                  const selectedDate = new Date(
                    splittedDate[1] +
                      '/' +
                      splittedDate[2] +
                      '/' +
                      splittedDate[0]
                  );
                  const gtToday = selectedDate > new Date();
                  if (!gtToday) {
                    return Swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: 'La fecha debe ser mayor a la fecha actual',
                    });
                  }
                  if (selectedDate) {
                    if (workshop.workshopDate !== selectedDate) {
                      setIsReschedule(true);
                    }
                    setWorkshop((prevWorkshop) => ({
                      ...prevWorkshop,
                      workshopDate: selectedDate,
                      workshopTextDate: format(
                        selectedDate,
                        'EEEE dd MMMM, yyyy'
                      ),
                    }));
                  }
                }
              }}
            />
          </div>
          <div className="form-row">
            <label htmlFor="place" className={`form-label label--icon`}>
              Lugares disponibles
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Lugares disponibles</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <select
              id="place"
              className="form-control form-select inputRequired"
              disabled={workshop.workshopType === 'telefono'}
              value={workshop.availablePlaces || ''}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setWorkshop((prevWorkshop) => ({
                  ...prevWorkshop,
                  availablePlaces: Number(selectedValue),
                }));
              }}
            >
              <option value="">Elige una opción</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
          <div className="form-row">
            <div className="row">
              <div className="col-6">
                <label htmlFor="time" className={`form-label label--icon`}>
                  Horario
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Horario</Tooltip>}
                  >
                    <span className="material-icons-outlined icon">
                      help_outline
                    </span>
                  </OverlayTrigger>
                </label>
                <select
                  id="time"
                  className="form-control form-select inputRequired"
                  value={workshop.workshopSchedule || ''}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setWorkshop((prevWorkshop) => ({
                      ...prevWorkshop,
                      workshopSchedule: selectedValue,
                    }));
                  }}
                >
                  <option value="">Elige una opción</option>
                  <option value="08:00 - 09:00 Hrs.">08:00 - 09:00 Hrs.</option>
                  <option value="09:00 - 10:00 Hrs.">09:00 - 10:00 Hrs.</option>
                  <option value="10:00 - 11:00 Hrs.">10:00 - 11:00 Hrs.</option>
                  <option value="11:00 - 12:00 Hrs.">11:00 - 12:00 Hrs.</option>
                  <option value="12:00 - 13:00 Hrs.">12:00 - 13:00 Hrs.</option>
                  <option value="13:00 - 14:00 Hrs.">13:00 - 14:00 Hrs.</option>
                  <option value="14:00 - 15:00 Hrs.">14:00 - 15:00 Hrs.</option>
                  <option value="15:00 - 16:00 Hrs.">15:00 - 16:00 Hrs.</option>
                  <option value="16:00 - 17:00 Hrs.">16:00 - 17:00 Hrs.</option>
                  <option value="17:00 - 18:00 Hrs.">17:00 - 19:00 Hrs.</option>
                  <option value="18:00 - 19:00 Hrs.">18:00 - 19:00 Hrs.</option>
                  <option value="19:00 - 20:00 Hrs.">19:00 - 20:00 Hrs.</option>
                </select>
              </div>
              <div className="col-6">
                <label htmlFor="modalidad" className={`form-label label--icon`}>
                  Modalidad
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Modalidad</Tooltip>}
                  >
                    <span className="material-icons-outlined icon">
                      help_outline
                    </span>
                  </OverlayTrigger>
                </label>
                <select
                  id="modalidad"
                  className="form-control form-select inputRequired"
                  value={workshop.workshopType || ''}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setWorkshop((prevWorkshop) => ({
                      ...prevWorkshop,
                      workshopType: selectedValue,
                    }));
                  }}
                >
                  <option value="">Elige una opción</option>
                  <option value="En línea">En línea</option>
                  <option value="Teléfono">Teléfono</option>
                  <option value="Presencial">Presencial</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={isButtonDisabled}
            >
              {id ? 'Actualizar' : 'Registrar'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default TallerCreateComponent;
