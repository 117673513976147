import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IAdmin } from '../../../types/adminInterface';
import LoaderComponent from '../../Loader/loader.component';
import { fetchStrapiDocs } from '../../../api/strapi';
import { strapiDoc } from '../../../types/strapiDocs';
interface AmigaInfoComponentProps {
  admin: IAdmin;
}

const AmigaInfoComponent: React.FC<AmigaInfoComponentProps> = ({
  admin: prevAdmin,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [formularioValido, setFormularioValido] = useState(false);
  const [countryList, setCountryList] = useState<strapiDoc[]>([]);
  const [stateList, setStateList] = useState<strapiDoc[]>([]);
  const [ocupaciones, setOcupaciones] = useState<strapiDoc[]>([]);
  const [nivelesEducativos, setNivelesEducativos] = useState<strapiDoc[]>([]);

  const calculateAge = (birthday: string) => {
    const birthDate = new Date(birthday);
    const difference = Date.now() - birthDate.getTime();
    const ageDate = new Date(difference);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const fetchStates = async (countryId: number) => {
    // Fetch states
    const query = {
      sort: ['nombre:asc'],
      filters: {
        pais: { $eq: countryId },
      },
      pagination: { pageSize: 100 },
      populate: '*',
    };
    const states = await fetchStrapiDocs('/estados', query);
    setStateList(states.data);
  };

  const fetchOcupaciones = async () => {
    const query = { sort: 'nombre:asc' };
    const ocupacionesData = await fetchStrapiDocs('ocupaciones', query);
    const nivelesEducativosData = await fetchStrapiDocs(
      'niveles-educativos',
      query
    );
    setOcupaciones(ocupacionesData.data);
    setNivelesEducativos(nivelesEducativosData.data);
  };

  const onFieldChange = (
    name: keyof IAdmin,
    value: string | { id: number; name: string }
  ) => {
    if (name === 'birthday') {
      setAdmin({
        ...admin,
        [name as string]: value,
        age: calculateAge(value as string),
      });
      return;
    }
    setAdmin({ ...admin, [name]: value });
  };

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll(
      '.requiredField'
    ) as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;

    camposRequeridos.forEach((campo) => {
      if (
        campo.value === undefined ||
        campo.value === null ||
        campo.value === '' ||
        campo.value === 'default'
      ) {
        todosCompletos = false;
      } else {
        const campoCompleto = campo.value.trim() !== '';
        todosCompletos = todosCompletos && campoCompleto;
      }
    });
    setFormularioValido(todosCompletos);
  };

  const handleSubmit = () => {
    const stateAdmin = { ...admin, ...prevAdmin };
    navigate('/nueva-amiga-colectiva', { state: { admin: stateAdmin } });
  };

  useEffect(() => {
    verifyFields();
  }, [admin]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch countries
        const query = { sort: ['nombre:asc'] };
        const countries = await fetchStrapiDocs('/paises', query);
        const data = countries.data;
        data.push({ id: 0, attributes: { nombre: 'Otro' } });
        setCountryList(countries.data);
        await fetchOcupaciones();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        if (prevAdmin && prevAdmin.username) {
          setAdmin(prevAdmin);
          if (prevAdmin.birthday) {
            setAdmin({
              ...prevAdmin,
              age: calculateAge(prevAdmin.birthday),
            });
          }
          if (prevAdmin.country?.id) {
            fetchStates(prevAdmin.country.id || 0);
          }
        }
      }
    };
    fetchData();
  }, []);

  return (
    <div className="form-block">
      {loading && <LoaderComponent />}
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label htmlFor="name" className="form-label label--icon">
            Nombre o seudónimo <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Nombre del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control requiredField"
            id="name"
            placeholder="Escribe el nombre"
            value={admin.username || ''}
            onChange={(e) => {
              onFieldChange('username', e.target.value);
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="date" className="form-label label--icon">
            Fecha de nacimiento <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Fecha de nacimiento.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="date"
            className="form-control requiredField"
            id="date"
            placeholder="dd/mm/aaaa"
            value={admin.birthday || ''}
            onChange={(e) => {
              onFieldChange('birthday', e.target.value);
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="age" className="form-label label--icon">
            Edad <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Edad del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="number"
            className="form-control requiredField"
            id="age"
            placeholder="Coloca los años cumplidos"
            value={admin.age && admin.age < 100 ? admin.age : ''}
            disabled={true}
            onChange={(e) => {
              e.preventDefault();
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="nacionalidad" className="form-label label--icon">
            Nacionalidad <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Nacionalidad del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <select
            id="nacionalidad"
            className="form-select requiredField"
            aria-label="Tu nacionalidad"
            value={admin.country?.id || 'default'}
            onChange={(e) => {
              const countryId = Number(e.target.value);
              const foundedCountry = countryList.find(
                ({ id }) => id === countryId
              );
              if (!foundedCountry) return;
              const value = {
                id: foundedCountry.id,
                name: foundedCountry.attributes.nombre,
              };
              onFieldChange('country', value);
              fetchStates(countryId);
            }}
          >
            <option value="default" disabled>
              Elige una de las opciones
            </option>
            {countryList.map((country) => (
              <option key={country.id} value={country.id}>
                {country.attributes.nombre}
              </option>
            ))}
          </select>
        </div>
        {stateList.length > 0 && (
          <div className="form-row">
            <label htmlFor="region" className="form-label label--icon">
              Región <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Región del usuario.</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <select
              id="region"
              className="form-select requiredField"
              aria-label="Tu región"
              value={admin.state?.id || 'default'}
              onChange={(e) => {
                const stateId = Number(e.target.value);
                const foundedState = stateList.find(({ id }) => id === stateId);
                if (!foundedState) return;
                const value = {
                  id: foundedState.id,
                  name: foundedState.attributes.nombre,
                };
                onFieldChange('state', value);
              }}
            >
              <option value="default" disabled>
                Elige una de las opciones
              </option>
              {stateList.map((state, index) => (
                <option key={index} value={state.id}>
                  {state.attributes.nombre}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="form-row">
          <label htmlFor="address" className="form-label label--icon">
            Dirección <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Dirección del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            id="address"
            type="text"
            className="form-control requiredField"
            placeholder="Escribe calle y número"
            value={admin.address || ''}
            onChange={(e) => {
              onFieldChange('address', e.target.value);
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="cp" className="form-label label--icon">
            Código postal <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Código postal del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            id="cp"
            type="number"
            className="form-control requiredField"
            placeholder="Escribe el C.P."
            value={admin.postalCode || ''}
            onChange={(e) => {
              onFieldChange('postalCode', e.target.value);
            }}
          />
        </div>
        <div className="form-row">
          <label htmlFor="ocupacion" className="form-label label--icon">
            Ocupaciones principales (trabajo, estudio){' '}
            <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Ocupaciones del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <select
            className="form-select"
            aria-label="Tu Ocupación"
            defaultValue={'default'}
            name="ocupacion"
            value={admin.principalJob || 'default'}
            onChange={(e) => {
              onFieldChange('principalJob', e.target.value);
            }}
          >
            <option value="default" disabled>
              Elige una de las opciones
            </option>
            {ocupaciones.map((ocupacion) => (
              <option key={ocupacion.id} value={ocupacion.attributes.nombre}>
                {ocupacion.attributes.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="form-row">
          <label htmlFor="nivel" className="form-label label--icon">
            Nivel educativo <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Nivel educativo del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <select
            id="nivel"
            className="form-select requiredField"
            aria-label="Tu nivel educativo"
            defaultValue={'default'}
            name="NivelEducativo"
            value={admin.educationLevel || 'default'}
            onChange={(e) => {
              onFieldChange('educationLevel', e.target.value);
            }}
          >
            <option value="default" disabled>
              Elige una de las opciones
            </option>
            {nivelesEducativos.map((nivel) => (
              <optgroup key={nivel.id} label={nivel.attributes.nombre}>
                {['en curso', 'completa', 'incompleta'].map((opcion, id) => (
                  <option
                    key={id}
                    value={`${nivel.attributes.nombre} ${opcion}`}
                  >
                    {`${nivel.attributes.nombre} ${opcion}`}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </div>
        <div className="form-row form--last">
          <button
            type="submit"
            className="btn btn--type1"
            disabled={!formularioValido}
          >
            Siguiente
          </button>
        </div>
        <div className="form-row form--last">
          <a href="/inicio-administrador" className="btn btn--type2">
            Cancelar
          </a>
        </div>
      </form>
    </div>
  );
};

export default AmigaInfoComponent;
