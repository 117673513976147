import React from 'react';
import DonationsItemComponent from '../Buttons/donationsItem.component';
import {DONATIONS_DATA} from '../../../utils/donations';

interface DonationsListComponentProps {
  numbers: number; // Define cuentomostrar prop
  onItemClick?: () => void;
}

const DonationsListComponent: React.FC<DonationsListComponentProps> = ({numbers, onItemClick}) => {
  // Add your component logic here

  return (
    <div className="donations-list__item">
      <ul>
        {DONATIONS_DATA.slice(0, numbers).map((donation, index) => (
          <li key={index}>
            <DonationsItemComponent
              label={donation.label}
              amount={donation.amount}
              onClick={onItemClick}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DonationsListComponent;