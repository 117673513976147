import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IUser } from '../../../types/userInterface';

interface PersonalDataProps {
  user: Partial<IUser>;
  setUser?: React.Dispatch<React.SetStateAction<Partial<IUser>>>;
  readonly?: boolean;
}

const PersonalDataComponent: React.FC<PersonalDataProps> = ({
  user,
  setUser,
  readonly,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (setUser) {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  };

  return (
    <div className="form-block">
      <form>
        <div className="form-row">
          <label htmlFor="name" className="form-label label--icon">
            Nombre
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Nombre de usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="username"
            name="username"
            placeholder="Nombre del usuario"
            readOnly={readonly}
            value={user.username || ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="email" className="form-label label--icon">
            Correo
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  Esta información es importante para poder tener un medio de
                  contacto con el usuario.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="tucorreo@dominio.com.cl"
            readOnly={readonly}
            value={user.email || ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="phone" className="form-label label--icon">
            Teléfono
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  Esta información es importante para mantenernos en contacto a
                  través de llamadas y/o chats.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="phone"
            className="form-control"
            id="phone"
            name="phone"
            placeholder="+1234567890"
            readOnly={readonly}
            value={user.phone || ''}
            onChange={handleInputChange}
          />
        </div>
      </form>
    </div>
  );
};

export default PersonalDataComponent;
