import React from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import LoginComponent from '../../../components/Auth/Login/login.component';
import FooterShared from '../../../shared/Header/footer.shared';

const LoginScreen = () => {
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="auth-row module-top row">
              <div className="col-md-5 col-12">
                <h1>Hola, bienvenida</h1>
                <LoginComponent />
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img
                    src="/assets/images/illustrations/women.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default LoginScreen;
