import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { deleteSession } from '../../utils/helpers';

interface Props {
  // Define your component props here
}

const HeaderAdminSearch: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [placeholder, setPlaceholder] = useState(
    'Buscar colectiva, mujer, taller o personal del equipo de Amigas'
  );
  const location = useLocation();

  const isActive = (path: string): string => {
    return location.pathname === path ? 'active' : '';
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setPlaceholder('Buscar...');
      } else {
        setPlaceholder(
          'Buscar colectiva, mujer, taller o personal del equipo de Amigas'
        );
      }
    };

    handleResize(); // Llama a la función al inicio para establecer el valor inicial

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleButtonClick = () => {
    setIsOpen(!isOpen); // Cambia el estado al opuesto de lo que está actualmente
  };

  const handleLogOut = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    deleteSession();
    setTimeout(() => {
      window.location.href = '/';
    }, 100);
  };

  return (
    <header className="header-admin pb-0">
      <div className="container">
        <div className="row">
          <div className="header-admin__col col-12">
            <section className="header-admin__top">
              <div className="header-search-module">
                <figure className="icon-user-pic">
                  <img
                    src="/assets/images/icons/user-pic-3.svg"
                    alt="User Admin"
                  />
                </figure>
                <div className="form-row form--search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={placeholder}
                  />
                  <i className="icon icon--search-bar"></i>
                </div>
              </div>
              <a href="/" className="icon-header ">
                <i className="icon icon--message"></i>
              </a>
              <button
                type="button"
                className={`open-nav ${isOpen ? 'active' : ''}`}
                onClick={handleButtonClick}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <nav className={`main-nav ${isOpen ? 'active' : ''}`}>
                <ul>
                  <li>
                    <a href="/colectivas">Colectivas</a>
                  </li>
                  <li>
                    <a href="/equipo-de-las-amigas">Equipo Amigas</a>
                  </li>
                  <li>
                    <a href="/mujeres">Mujeres</a>
                  </li>
                  <li>
                    <a href="/documentos">Documentos</a>
                  </li>
                  <li>
                    <a href="/" onClick={(event) => handleLogOut(event)}>
                      <strong>Cerrar sesión</strong>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/nueva-amiga"
                      className={`btn btn--type1 ${isActive(
                        '/registrar-una-mujer'
                      )}`}
                    >
                      Registrar una mujer
                    </a>
                  </li>
                </ul>
              </nav>
            </section>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderAdminSearch;
