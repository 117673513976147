import React from 'react';

interface ColectivaItemComponentProps {
  url: string;
  colectiva: string;
  organizer: string;
  amount: number;
  quantity: number;
}

const ColectivaItemComponent: React.FC<ColectivaItemComponentProps> = ({url, colectiva, organizer, amount, quantity}) => {
  const formattedAmount = amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const formattedQuantity = quantity.toLocaleString();

  return (
    <a href={url} className="btn btn--colectiva">
      <p>
        <strong className="text-purple500">{colectiva}</strong>
        <span className="text-400">{organizer} • <small className="text-16 text-gray600">{formattedQuantity}</small> <small className="text-16 text-gray600">mujeres</small></span>
      </p>
      <span className="data-table__number">${formattedAmount} CLP</span>
    </a>
  );
};

export default ColectivaItemComponent;