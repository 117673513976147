import React, {useState} from 'react';
import HeaderAdminShared from '../../../shared/Header/headerAdmin.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import { ADMIN_DATA } from '../../../utils/adminUsers';
import { TALLERISTAS_DATA } from '../../../utils/talleristas';
import AdminCardProps from '../../../components/Elements/AdminCard/adminCard.component';
import ReactSearchAutocomplete from 'react-search-autocomplete/dist/components/ReactSearchAutocomplete';
import CustomPagination from '../../../components/Elements/Paginator/paginator.component';

interface Item {
  id: number;
  name: string;
}


const TalleristasScreen: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20); // Cambia aquí el número de elementos por página

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = TALLERISTAS_DATA.slice(indexOfFirstItem, indexOfLastItem);
  const totalUsers = TALLERISTAS_DATA.length;
  const handleChangePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };


  const handleOnSearch = (string: string, results: Item[]) => {
    console.log(string, results);
  }

  const handleOnSelect = (item: Item) => {
    // the item selected
    console.log(item)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const handleOnHover = (result: Item) => {
    // the item hovered
    console.log(result)
  }

  const formatResult = (item: Item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }
  
  
  return (
   <>
     <HeaderAdminShared />
      <section className="module container">
        <div className="body-row row mb-4">
          <div className="col-12">
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <h1>Equipo de Las Amigas</h1>
                <p className="text-20"><strong>Talleristas de Colectiva</strong></p>
              </div>
              <div className="col-12 mb-4">
                <a href="/equipo-de-las-amigas" className="btn--back"><i className="icon icon--back"></i> Regresar</a>
              </div>
              <div className="col-12">
                <h2 className="h3">Busca entre Las Amigas</h2>
                <div className="form-search-complete">
                  <div className="autocomplete-search search--100">
                    <ReactSearchAutocomplete
                      items={ADMIN_DATA}
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      onFocus={handleOnFocus}
                      autoFocus
                      formatResult={formatResult}
                      placeholder="Buscar colectiva"
                      className="autocomplete-search__input"
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="row">
          <div className="mt-4 user-info-tab">
            <h4 className="user-info-tab__subtitle-right text-20 text-regular text-purple800">
              {totalUsers} Talleristas de Colectiva registradas
            </h4>

            <hr className="mt-0 hr-pink"/>
            <div className="row">
            {currentItems.map((tallerista, index) => (
                <div className="user-info-tab__col col-md-3 col-sm-6 col-12" key={index}>
                  <AdminCardProps
                    name={tallerista.name}
                    role={tallerista.position}
                    url={tallerista.url}
                    title="h3"
                  />
                </div>
              ))}
              <div className="data-paginator">
                <CustomPagination
                  rowsPerPage={itemsPerPage}
                  rowCount={TALLERISTAS_DATA.length}
                  onChangePage={handleChangePage}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </div>
        
        </div>
      </section>
    <FooterShared />
   </>
  );
};

export default TalleristasScreen;