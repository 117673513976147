import React from 'react';

const FooterShared: React.FC = () => {

  return (
   <footer>
    <div className="container">
      <div className="row">
        <div className="footer__col col-lg-4 col-12">
          <a href="/" className="footer__logo">
            <figure>
              <img src="/assets/images/logo/salud-logo-purple.png" alt="Red de Salud Comunitaria" />
            </figure>
          </a>
          <article>
            <p className="text-20">Queremos que te sientas segura y en confianza.</p>
          </article>
          <ul className="quicklinks">
            <li>
              <a href="#" className="text-20">Política de privacidad</a>
            </li>
            <li>
              <a href="#" className="text-20">Términos y condiciones</a>
            </li>
          </ul>
        </div>
        <div className="footer__col col-lg-4 col-12">
         <div className="footer__row">
          <h3 className="h2">Nosotras</h3>
          <a href="#">Blog</a>
         </div>
         <ul className="social">
          <li>
            <a href="https://www.facebook.com/profile.php?id=100071239834790" target='_blank' rel="noopener noreferrer">
              <i className="icon icon--facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/conlasamigasyenlacasa/" target='_blank' rel="noopener noreferrer">
              <i className="icon icon--instagram"></i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/conlasamigas" target='_blank' rel="noopener noreferrer">
              <i className="icon icon--twitter"></i>
            </a>
          </li>
         </ul>
        </div>
        <div className="footer__col col-lg-4 col-12">
         <div className="footer__row">
          <h3 className="h2">Contáctanos</h3>
         </div>
         <ul className="contact">
          <li>
            <a href="tel:+56982729131" target='_blank' rel="noopener noreferrer" className="text-20">
              <i className="icon icon--phone"></i>
              <span>(+56) 982 729 131</span>
            </a>
          </li>
          <li>
            <a href="mailto:contacto@conlasamigas.org" target='_blank' rel="noopener noreferrer" className="text-20">
              <i className="icon icon--email"></i>
              <span>contacto@conlasamigas.org</span>
            </a>
          </li>
          
         </ul>
        </div>
      </div>
      <div className="footer__quicklinks mb-4 row">
        <div className="col-lg-6 col-12">
          <ul className="quicklinks">
            <li>
              <a href="#" className="text-20">Política de privacidad</a>
            </li>
            <li>
              <a href="#" className="text-20">Términos y condiciones</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="text-center col-12">
          <p>Copyright © 2024 Las Amigas. All rights reserved.</p>
        </div>
      </div>
    </div>
   </footer>
  );
};

export default FooterShared;
