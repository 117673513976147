import React from 'react';

interface TallerItemDateComponentProps {
  title: string;
  organizer: string;
  date: string;
  url: string;
}

const TallerItemDateComponent: React.FC<TallerItemDateComponentProps> = ({ title, organizer, date, url }) => {

  return (
    <a href={url} className="btn btn--taller">
      <figure className="icon-user-pic">
        <img src="/assets/images/icons/user-pic-2.svg" alt="User"/>
      </figure>
      <p>
        <strong className="text-purple500">{title}</strong>
        <span>{organizer} • <small className="text-16 text-gray600">{date}</small></span>
      </p>
    </a>
  );
};

export default TallerItemDateComponent;