import React from 'react';
import { Modal } from 'react-bootstrap';

interface ModalDeleteTagComponentProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
}

const ModalDeleteTagComponent: React.FC<ModalDeleteTagComponentProps> = ({ show, onHide, onConfirm }) => {
 
  const handleModalClose = () => {
    onHide();
  
  };


  const handleConfirmDelete = () => {
    onConfirm(); // Llamar a la función de confirmación
  };


  return (
    <>
      <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal--gray modal--md"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="text-center"><i className="icon icon--tag"></i> Eliminar etiqueta</h2>
        </Modal.Title>
       
      </Modal.Header>
      <Modal.Body>
        <p className="text-20 text-500 text-center">¿Deseas eliminar esta MEDIANA?</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="double-btn-action">
          <button
            type="button"
            className="btn btn--type2"
            onClick={handleModalClose} 
          >Cancelar</button>
          <button
            type="button"
            className="btn btn--type1"
            onClick={handleConfirmDelete} 
          >Eliminar</button>
        </div>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default  ModalDeleteTagComponent;