import React from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import TagsComponent from '../../../../components/Forms/tags/tags.component';
import { TAGS_DATA } from '../../../../utils/tags';
import TagsManagerComponent from '../../../../components/Forms/tagsManager/tagsManager.component';
import DonationsBlockComponent from '../../../../components/Elements/Donations/donationsBlock.component';
import ColectivaTableComponent from '../../../../components/Elements/DataTable/colectivaTable.component';
import AdminCardProps from '../../../../components/Elements/AdminCard/adminCard.component';
import TalleresComponent from '../../../../components/Elements/Talleres/talleres.component';
import ActionsColectivaButtonComponent from '../../../../components/Elements/Buttons/actionsColectiva.component';

const CoordinadoraDetailScreen: React.FC = () => {
  const tagsShow = ['tag-10']; // Definir los id de las tags que se quieren mostrar

  const tagsFiltered = TAGS_DATA.filter((tag) => tagsShow.includes(tag.id));
  return (
    <>
      <HeaderShared />
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom">
            <div className="row">
              <div className="col-12 mb-3">
                <a href="/mi-colectiva" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="view-head__data col-12">
                <div className="row view-head-row">
                  <div className="view-detail col-md-8 col-12">
                    <h1 className="text-40">Valeria</h1>
                    <p className="text-20">Coordinadora de Coletiva</p>
                    <div className="view-detail__tags">
                      <TagsComponent tags={tagsFiltered} />
                    </div>
                    <div className="col-12 mb-5">
                      <p className="text-gray600 mb-0">
                        <strong>Mi tiempo de respuesta</strong>
                      </p>
                      <p className="text-icon">
                        <i className="icon icon--clock"></i> menos de 10 minutos
                      </p>
                    </div>
                  </div>
                  <aside className="view-detail__action no-flex  col-md-4 col-12">
                    <div className="view-detail__action-col col-12 col-reset mb-3">
                      <a href="/" className="btn btn--chat">
                        Ver Chat <i className="icon icon--chat"></i>
                      </a>
                      <ActionsColectivaButtonComponent />
                    </div>
                  </aside>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-6 col-12  mb-4">
                    <table className="table-user-data">
                      <tbody>
                        <tr>
                          <td>
                            <p className="data-info">
                              <i className="icon icon--message-clip"></i>{' '}
                              Registrada:
                            </p>
                          </td>
                          <td>18/01/2023 (24 horas)</td>
                        </tr>
                        <tr>
                          <td>
                            <p className="data-info">
                              <i className="icon icon--user-clip"></i>{' '}
                              Acompañamientos:
                            </p>
                          </td>
                          <td>14 mujeres</td>
                        </tr>
                        <tr>
                          <td>
                            <p className="data-info">
                              <i className="icon icon--pin"></i> Colectiva:
                            </p>
                          </td>
                          <td>Villa del Mar</td>
                        </tr>

                        <tr>
                          <td>
                            <p className="data-info">
                              <i className="icon icon--edit-clip"></i>Rol
                              Principal:
                            </p>
                          </td>
                          <td>Coordinadora</td>
                        </tr>

                        <tr>
                          <td>
                            <p className="data-info">
                              <i className="icon icon--edit-clip"></i> Rol
                              Secundario:
                            </p>
                          </td>
                          <td>
                            <span className="text-gray600">Sin Asignar</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="col-12 col-reset">
                      <h4 className="mb-3">Especialista en</h4>
                      <TagsManagerComponent
                        admin={{}}
                        user={{}}
                        showLabel={false}
                      />
                    </div>
                  </div>
                  <aside className="col-lg-5 offset-lg-1 col-12">
                    <DonationsBlockComponent
                      quantity={10100}
                      donations={3400}
                      increment={80.1}
                      button={true}
                    />
                  </aside>
                </div>
                <div className="row">
                  <div className="dashboard-info col-12">
                    <h4 className="mb-4">Mujeres en la colectiva</h4>
                    <div className="col-12 col-reset mb-4">
                      <ColectivaTableComponent />
                    </div>
                    <h4 className="mb-4">Integrantes en su colectiva</h4>
                    <div className="mt-4 user-info-tab">
                      <h4 className="text-20 text-regular text-purple800">
                        Acompañantes
                      </h4>
                      <hr className="mt-0 hr-pink" />
                      <div className="row">
                        <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                          <AdminCardProps
                            name="Rafaela"
                            role="Acompañante"
                            responseTime="Menos de 10 minutos"
                            url="/detalle-acompanante"
                          />
                        </div>
                        <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                          <AdminCardProps
                            name="Ana"
                            role="Acompañante"
                            responseTime="Menos de 10 minutos"
                            url="/detalle-acompanante"
                          />
                        </div>
                        <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                          <AdminCardProps
                            name="María"
                            role="Acompañante"
                            responseTime="Menos de 10 minutos"
                            url="/detalle-acompanante"
                          />
                        </div>
                        <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                          <AdminCardProps
                            name="Lucy"
                            role="Acompañante"
                            responseTime="Menos de 10 minutos"
                            url="/detalle-acompanante"
                          />
                        </div>
                        <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                          <AdminCardProps
                            name="Mar"
                            role="Acompañante"
                            responseTime="Menos de 10 minutos"
                            url="/detalle-acompanante"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 user-info-tab">
                      <h4 className="text-20 text-regular text-purple800">
                        Talleristas
                      </h4>
                      <hr className="mt-0 hr-pink" />
                      <div className="row">
                        <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                          <AdminCardProps
                            name="Rita"
                            role="Tallerista"
                            responseTime="Menos de 10 minutos"
                            url="/detalle-tallerista"
                          />
                        </div>
                        <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                          <AdminCardProps
                            name="Victoria"
                            role="Tallerista"
                            responseTime="Menos de 10 minutos"
                            url="/detalle-tallerista"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 user-info-tab">
                      <h4 className="text-20 text-regular text-purple800">
                        Entregadoras
                      </h4>
                      <hr className="mt-0 hr-pink" />
                      <div className="row">
                        <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                          <AdminCardProps
                            name="Eugenia"
                            role="Entregadora"
                            responseTime="Menos de 10 minutos"
                            url="/detalle-entregadora"
                          />
                        </div>
                        <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                          <AdminCardProps
                            name="Simone"
                            role="Entregadora"
                            responseTime="Menos de 10 minutos"
                            url="/detalle-entregadora"
                          />
                        </div>
                        <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                          <AdminCardProps
                            name="Eva"
                            role="Entregadora"
                            responseTime="Menos de 10 minutos"
                            url="/detalle-entregadora"
                          />
                        </div>
                        <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                          <AdminCardProps
                            name="Mónica"
                            role="Entregadora"
                            responseTime="Menos de 10 minutos"
                            url="/detalle-entregadora"
                          />
                        </div>
                        <div className="user-info-tab__col col-md-3 col-sm-6 col-12">
                          <AdminCardProps
                            name="Yolanda"
                            role="Entregadora"
                            responseTime="Menos de 10 minutos"
                            url="/detalle-entregadora"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 user-info-tab">
                      <h4 className="text-20 text-regular text-purple800">
                        Talleres activos
                      </h4>
                      <hr className="mt-0 hr-pink" />
                      <TalleresComponent
                        mode="ver-mas"
                        showAvailableSlotsBadge={false}
                        showStockList={false}
                        cols="col-xl-3"
                        workshop={{}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default CoordinadoraDetailScreen;
