import React from 'react';
import TallerItemDateComponent from '../Buttons/tallerItem.component';
import {TALLERES_COMING_DATA} from '../../../utils/talleresComing';

interface TallerItemDateComponentProps {
}

const TalleresListDateComponent: React.FC<TallerItemDateComponentProps> = () => {

  return (
    <div className="talleres-list">
      <ul>
      {TALLERES_COMING_DATA.slice(0, 3).map((taller, index) => (
          <li key={index}>
            <TallerItemDateComponent
              title={taller.title}
              organizer={taller.organizer}
              date={taller.date}
              url={taller.url}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TalleresListDateComponent;