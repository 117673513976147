import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface ModalTallerSubmitProps {
  show: boolean;
  onHide: () => void;
  handleConfirm: () => Promise<void>;
  attendersCount: {
    total: number;
    attended: number;
    apuntes: number;
  };
}
const ModalTallerSubmitComponents: React.FC<ModalTallerSubmitProps> = ({
  show,
  onHide,
  handleConfirm,
  attendersCount,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="text-40 text-center text-500 pb-2">
            Estás a punto de cerrar el taller con los siguientes datos
            recolectados:
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-3">
          <i className="icon icon--check-assistance"></i>
          <p className="text-24">Confirmas la asistencia de</p>
          <p className="text-40">
            {attendersCount.attended} de {attendersCount.total} mujeres
          </p>
        </div>
        <div className="text-center mb-3">
          <i className="icon icon--check-done"></i>
          <p className="text-24">Total de mujeres que dieron apuntes</p>
          <p className="text-40">{attendersCount.apuntes} mujeres</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-1">
          <a href="#" className="btn btn--type1" onClick={handleConfirm}>
            Confirmar y cerrar el taller
          </a>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn--simple text-purple500"
            onClick={onHide}
          >
            No quiero cerrar aún este taller
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTallerSubmitComponents;
