import React from 'react';
import HeaderAdminShared from '../../../../shared/Header/headerAdmin.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import AcompananteDataComponent from '../../../../components/Forms/adminDetail/acompananteDetail.component';

interface EditAdminScreenProps {
  // Define your component props here
}

const EditAdminScreen: React.FC<EditAdminScreenProps> = () => {
  // Add your component logic here

  return (
    <>
       <HeaderAdminShared />
        <section className="module container">
          <div className="body-row row">
            <div className="col-12">
            
              <div className="auth-row row">
                <div className="col-12 mb-4">
                  <a href="/detalle-usuario" className="btn--back"><i className="icon icon--back"></i> Regresar</a>
                </div>
                <div className="col-md-5 col-12">
                  <h1 className="mb-5">Editar información</h1>
                  <h2 className="h3">Información personal</h2>
                  <AcompananteDataComponent />
                  <div className="col-12 mt-3">
                    <a href="/editar-administrador-finalizado" className="btn btn--type1 btn--100">Guardar cambios</a>
                  </div>
                </div>
                <aside className="auto-row__right col-md-7 col-12">
                  <figure className="image-280">
                    <img src="/assets/images/illustrations/about.png" alt="Women" />
                  </figure>
                </aside>
              </div>
            </div>
          </div>
        </section>
       <FooterShared />
    </>
  );
};

export default EditAdminScreen