import React from 'react';
import ModalDerivarComponents from '../Modal/ModalDerivar/modalDerivar.component';
import ModalTallerComponents from '../Modal/ModalTaller/modalTaller.component';
import { IUser } from '../../../types/userInterface';

type NotificationsComponentProps = {
  user: Partial<IUser>;
  message: string;
  buttonText?: string;
  buttonUrl?: string;
  status: 'neutral' | 'warning' | 'danger' | 'info' | 'success';
  onButtonClick?: () => void;
  updateAmiga?: (updatedUser: Partial<IUser>) => Promise<void>;
};

const NotificationsComponent: React.FC<NotificationsComponentProps> = ({
  user,
  message,
  buttonText,
  buttonUrl,
  status,
  onButtonClick,
  updateAmiga,
}) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowTaller, setModalShowTaller] = React.useState(false);

  const handleButtonClick = () => {
    if (status === 'danger') {
      setModalShowTaller(true);
    } else if (
      status === 'neutral' ||
      status === 'warning' ||
      status === 'info' ||
      status === 'success'
    ) {
      setModalShow(true);
    } else if (onButtonClick) {
      onButtonClick();
    }
  };

  return (
    <>
      <ModalDerivarComponents
        show={modalShow}
        onHide={() => setModalShow(false)}
        user={user}
        updateAmiga={updateAmiga}
      />
      <ModalTallerComponents
        show={modalShowTaller}
        onHide={() => setModalShowTaller(false)}
        user={{}}
      />
      <div className={`notification-message message--${status}`}>
        <div className={`col-12 ${buttonUrl ? 'mb-5' : ''}`}>
          <p>
            <i className="fa-regular fa-flag"></i>{' '}
            <span dangerouslySetInnerHTML={{ __html: message }}></span>
          </p>
        </div>
        {buttonUrl && (
          <div className="col-12 d-flex justify-content-center">
            <button
              type="button"
              className="btn btn--type1"
              onClick={handleButtonClick}
            >
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationsComponent;
