import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { signIn } from '../../../api/adminApi';
import Swal from 'sweetalert2';
import LoaderComponent from '../../Loader/loader.component';
import { saveSession } from '../../../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { IAdmin } from '../../../types/adminInterface';

const LoginComponent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  // Función para manejar el clic en el botón de toggle
  const handleToggleClick = () => {
    setIsPasswordVisible(!isPasswordVisible); // Cambia el estado al opuesto de lo que está actualmente
  };

  const validateForm = () => {
    // Aquí puedes implementar tu lógica de validación más compleja si es necesario
    const emailIsValid = email.includes('@'); // Validación de ejemplo para el correo electrónico
    const passwordIsValid = password.length > 6; // Validación de ejemplo para la contraseña

    setIsEmailValid(emailIsValid);
    setIsPasswordValid(passwordIsValid);

    return emailIsValid && passwordIsValid;
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setIsEmailValid(true); // Restablece la alerta de validación para el correo electrónico
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setIsPasswordValid(true); // Restablece la alerta de validación para la contraseña
  };

  const homeNavigate = (admin: IAdmin) => {
    const { principalRol } = admin;
    console.log('principalRol', admin);
    switch (principalRol) {
      case 'Admin':
        navigate('/inicio-administrador');
        break;
      case 'Tallerista':
        navigate('/inicio-tallerista');
        break;
      case 'Acompañante':
        navigate('/inicio-acompanante');
        break;
      default:
        navigate('/');
        break;
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        const response = await signIn({ email, password });
        if (response) {
          saveSession(response);
          return homeNavigate(response);
        }
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Correo electrónico o contraseña incorrectos',
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Correo electrónico o contraseña incorrectos',
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Por favor, completa los campos correctamente',
      });
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <LoaderComponent />}
      <div className="form-block">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label
              htmlFor="email"
              className={`form-label label--icon ${
                !isEmailValid ? 'error' : ''
              }`}
            >
              Correo electrónico
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>Ingresa un correo electrónico válido.</Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="email"
              className={`form-control ${!isEmailValid ? 'error' : ''}`}
              id="email"
              placeholder="Ingresa tu correo electrónico"
              value={email}
              onChange={handleEmailChange}
            />
            {!isEmailValid && (
              <Alert variant="danger" className="alert--small mt-3">
                El correo electrónico es incorrecto.
              </Alert>
            )}
          </div>
          <div className="form-row">
            <label
              htmlFor="password"
              className={`form-label label--icon ${
                !isPasswordValid ? 'error' : ''
              }`}
            >
              Contraseña
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Ingresa tu contraseña que creaste para tu cuenta.
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <div className="form-password">
              <button
                type="button"
                className="toggle-password"
                onClick={handleToggleClick}
              >
                <span className="material-icons-outlined icon">
                  {isPasswordVisible ? 'visibility_off' : 'visibility'}
                </span>
              </button>
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                className={`form-control ${!isPasswordValid ? 'error' : ''}`}
                id="password"
                placeholder="Ingresa tu contraseña"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            {!isPasswordValid && (
              <Alert variant="danger" className="alert--small mt-3">
                La contraseña es incorrecta.
              </Alert>
            )}
          </div>
          <div className="form-row form--last">
            <button type="submit" className="btn btn--type1">
              Entrar
            </button>
          </div>
          <div className="form-row form--last">
            <a href="/recuperar-contrasena" className="btn--simple">
              Olvidé mi contraseña
            </a>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginComponent;
