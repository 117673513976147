import React from 'react';
import { Modal } from 'react-bootstrap';

interface ModalDeleteSuccessComponentProps {
  show: boolean;
  onHide: () => void;
}

const ModalDeleteSuccessComponent: React.FC<
  ModalDeleteSuccessComponentProps
> = ({ show, onHide }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal--gray"
      >
        <Modal.Header className="justifify-content-center">
          <Modal.Title id="contained-modal-title-vcenter" className="col-12">
            <h2 className="h3 text-center">Cuenta eliminada</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 d-flex justify-content-center">
            <figure className="image-280">
              <img src="/assets/images/illustrations/women.png" alt="Success" />
            </figure>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12 d-flex justify-content-center mb-3">
            <a href="/mi-colectiva" className="btn btn--type1  btn--370">
              Regresar a la colectiva
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalDeleteSuccessComponent;
