export const SCORE_DATA = [
  {
    "id": 1,
    "user": "Paulina Pimentel",
    "date": "12/03/2023",
    "message": "Gracias a Las Amigas por darnos el acceso a nuestro derecho de elección, por estar siempre al pendiente de una cuando estamos iniciando el procedimiento. Por hacer que nuestros derechos se respeten dentro de los hospitales.",
    "types": [
      {
        "label": "Acompañamiento",
        "score": 4,
      },
      {
        "label": "Comunicación",
        "score": 5,
      },
      {
        "label": "Seguimiento",
        "score": 2,
      }
    ]
  },
  {
    "id": 2,
    "user": "Esther Sierra",
    "date": "12/03/2023",
    "message": "Gracias a Las Amigas por darnos el acceso a nuestro derecho de elección, por estar siempre al pendiente de una cuando estamos iniciando el procedimiento. Por hacer que nuestros derechos se respeten dentro de los hospitales.",
    "types": [
      {
        "label": "Acompañamiento",
        "score": 2,
      },
      {
        "label": "Comunicación",
        "score": 1,
      },
      {
        "label": "Seguimiento",
        "score": 2,
      }
    ]
  },
  {
    "id": 3,
    "user": "Javiera Mena",
    "date": "12/03/2023",
    "message": "Gracias a Las Amigas por darnos el acceso a nuestro derecho de elección, por estar siempre al pendiente de una cuando estamos iniciando el procedimiento. Por hacer que nuestros derechos se respeten dentro de los hospitales.",
    "types": [
      {
        "label": "Acompañamiento",
        "score": 4,
      },
      {
        "label": "Comunicación",
        "score": 5,
      },
      {
        "label": "Seguimiento",
        "score": 2,
      }
    ]
  },
]