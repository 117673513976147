import React, {useState, useEffect} from 'react';
import DataTable, { TableColumn }  from 'react-data-table-component';
import CustomPagination from '../Paginator/paginator.component'
import { Accordion } from 'react-bootstrap';

interface DataRow {
  id: number;
  name: string;
  rol: string;
  colectiva: string;
  pic: string;
  url: string;
}

const UserTableComponent: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1200);
  const rowsPerPage = 10;
  const [rows, setRows] = useState<DataRow[]>([
    {
      id: 1,
      name: 'Paulina Pimentel',
      rol: 'Acompañante',
      colectiva: 'Valparaíso',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 2,
      name: 'Maria Rodriguez',
      rol: 'Acompañante',
      colectiva: 'Santiago',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 3,
      name: 'Ana Lopez',
      rol: 'Acompañante',
      colectiva: 'Valparaíso',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 4,
      name: 'Laura Garcia',
      rol: 'Acompañante',
      colectiva: 'Santiago',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 5,
      name: 'Isabella Martinez',
      rol: 'Acompañante',
      colectiva: 'Valparaíso',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 6,
      name: 'Sofia Hernandez',
      rol: 'Acompañante',
      colectiva: 'Santiago',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 7,
      name: 'Camila Gonzalez',
      rol: 'Acompañante',
      colectiva: 'Valparaíso',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 8,
      name: 'Valentina Perez',
      rol: 'Acompañante',
      colectiva: 'Santiago',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 9,
      name: 'Luciana Torres',
      rol: 'Acompañante',
      colectiva: 'Valparaíso',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 10,
      name: 'Martina Ramirez',
      rol: 'Acompañante',
      colectiva: 'Santiago',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 11,
      name: 'Julia Flores',
      rol: 'Acompañante',
      colectiva: 'Valparaíso',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 12,
      name: 'Emma Castro',
      rol: 'Acompañante',
      colectiva: 'Santiago',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 13,
      name: 'Mia Morales',
      rol: 'Acompañante',
      colectiva: 'Valparaíso',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 14,
      name: 'Luna Silva',
      rol: 'Acompañante',
      colectiva: 'Santiago',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 15,
      name: 'Valeria Vargas',
      rol: 'Acompañante',
      colectiva: 'Valparaíso',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 16,
      name: 'Antonella Castro',
      rol: 'Acompañante',
      colectiva: 'Santiago',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 17,
      name: 'Josefa Morales',
      rol: 'Acompañante',
      colectiva: 'Valparaíso',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 18,
      name: 'Florencia Silva',
      rol: 'Acompañante',
      colectiva: 'Santiago',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 19,
      name: 'Emilia Vargas',
      rol: 'Acompañante',
      colectiva: 'Valparaíso',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    },
    {
      id: 20,
      name: 'Agustina Castro',
      rol: 'Acompañante',
      colectiva: 'Santiago',
      pic: '/assets/images/icons/user-pic-2.svg',
      url: '/detalle-acompanante'
    }
  ]);

  const handleNameClick = (id: number) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, isNew: false };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: 'Nombre',
      selector: (row: DataRow) => row.name,
      format: (row: DataRow) => {
        return (
          <a href={row.url} className={`text-purple500 text-uppercase user-table-detail-image`} onClick={() => handleNameClick(row.id)}>
            <figure className="image-39">
              <img src={row.pic} alt="User image" />
            </figure>
            <strong>{row.name}</strong>
          </a>
        );
      }
    },
    {
      name: 'Rol',
      selector: (row: DataRow) => row.rol,
    },
    {
      name: 'Colectiva',
      selector: (row: DataRow) => row.colectiva,
    },
   
  ];
  

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };


  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="data-table table--result">

    {!isMobileView ? (
        <DataTable
            columns={columns}
            data={rows}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationComponent={props => {
              const handleChangePage = (page: number) => {
                console.log('Changing page to', page);
                // Asume que props.onChangePage realmente espera un solo argumento.
                // Esto es para diagnóstico; revisa la consola para ver qué se registra cuando cambias de página.
                (props.onChangePage as (page: number) => void)(page);

              };
            
              return (
                <div className="data-paginator">
                  <CustomPagination
                    rowsPerPage={props.rowsPerPage} // eslint-disable-next-line react/prop-types
                    rowCount={props.rowCount} // eslint-disable-next-line react/prop-types
                    onChangePage={handleChangePage}
                    currentPage={currentPage}
                  />
                </div>
              );
            }}
            
            onChangePage={page => setCurrentPage(page)}
            paginationPerPage={rowsPerPage}
            paginationTotalRows={rows.length}
        />
      
    ): (
    <section className="data-table-mobile">
          <div className="data-table-mobile__header">
            <p><strong>Nombre</strong></p>
          </div>
          <Accordion className="data-table-mobile__accordion">
            {rows.map((row, index) => (
              
               (() => {
                
                return(
                  <Accordion.Item eventKey={String(index)} key={row.id}>
                  <Accordion.Header>{row.name}</Accordion.Header>
                  <Accordion.Body>
                    <table>
                      <tr>
                        <td className="text-center" colSpan={2}>
                          <figure className="image-60 mh-auto">
                            <img src={row.pic} alt="User image" />
                          </figure>
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Nombre:</strong></td>
                        <td className="text-center">{row.name}</td>
                      </tr>
                      <tr>
                        <td><strong>Rol:</strong></td>
                        <td className="text-center">{row.rol}</td>
                      </tr>
                     
                      <tr>
                        <td><strong>Colectiva:</strong></td>
                        <td className="text-center">{row.colectiva}</td>
                      </tr>
                    
                      <tr>
                        <td colSpan={2} className="text-center">
                          <a href="/detalle-usuario" className="btn btn--type2">
                            <strong>Ver ficha</strong>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
                )
              })()
            ))}
          </Accordion>
        </section>

    )}
     
    </div>
  );
};

export default UserTableComponent;
