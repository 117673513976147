import React, {useState, useEffect} from 'react';
import DonationsListComponent from '../../../components/Elements/Donations/donationsList.component';
import Calendar from 'react-calendar';

interface DonationTabsComponentProps {
  showDonationDetail: () => void;
}

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DonationTabsComponent: React.FC<DonationTabsComponentProps> = ({showDonationDetail}) => {
  // Add state to track active button and calendar visibility
  const [activeButton, setActiveButton] = useState<string>('all');
  const [value, setValue] = useState<Value>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [calendarVisible, setCalendarVisible] = useState<boolean>(true); // Calendar initially visible
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  // Function to handle button click
  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
    if (buttonName === 'date') {
      setCalendarVisible(true);
      setSelectedDate(null);
    }
  };

  const handleDateChange = (newValue: Value) => {
    setValue(newValue);
    // Update selectedDate state
    if (Array.isArray(newValue)) {
      setSelectedDate(newValue[0]);
    } else {
      setSelectedDate(newValue);
      // Hide calendar after selecting a date
      setCalendarVisible(false);
    }
  };

  // Define los tipos para los parámetros de la función tileDisabled
  const tileDisabled = ({ date, view }: { date: Date, view: string }) => {
    const currentYear = new Date().getFullYear();
    if (view === 'year') {
      return date.getFullYear() < currentYear - 1;
    }
    return false;
  };

  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  const handleItemClick = () => {
    // Lógica para manejar clics en elementos de la lista
    console.log('Item clicked!');
    // Llamar a showDonationDetail para mostrar el detalle del donativo
    showDonationDetail();
  };

  return (
    // JSX code for your component's UI
    <>
    {!isMobileView ? (
      <nav className="data-info-tab mb-3">
      <ul>
        <li>
          <button
            type='button'
            className={activeButton === 'all' ? 'active' : ''}
            onClick={() => handleButtonClick('all')}
          >
            Todos
          </button>
        </li>
        <li>
          <button
            type='button'
            className={activeButton === 'today' ? 'active' : ''}
            onClick={() => handleButtonClick('today')}
          >
            Hoy
          </button>
        </li>
        <li>
          <button
            type='button'
            className={activeButton === 'yesterday' ? 'active' : ''}
            onClick={() => handleButtonClick('yesterday')}
          >
            Ayer
          </button>
        </li>
        <li>
          <button
            type='button'
            className={activeButton === '7days' ? 'active' : ''}
            onClick={() => handleButtonClick('7days')}
          >
            7 días
          </button>
        </li>
        <li>
          <button
            type='button'
            className={activeButton === '15days' ? 'active' : ''}
            onClick={() => handleButtonClick('15days')}
          >
            15 días
          </button>
        </li>
        <li>
          <button
            type='button'
            className={activeButton === '30days' ? 'active' : ''}
            onClick={() => handleButtonClick('30days')}
          >
            30 días
          </button>
        </li>
        <li>
          <button
            type='button'
            className={activeButton === 'date' ? 'active' : ''}
            onClick={() => handleButtonClick('date')}
          >
            Por fecha <i className="icon icon--calendar-color "></i>
          </button>
        </li>
      </ul>
      </nav>

    ): (

      <div className="form-row">
      <select
        onChange={(e) => handleButtonClick(e.target.value)}
        className="form-select"
      >
        <option value="all">Todos</option>
        <option value="today">Hoy</option>
        <option value="yesterday">Ayer</option>
        <option value="7days">7 días</option>
        <option value="15days">15 días</option>
        <option value="30days">30 días</option>
        <option value="date">Por fecha</option>
      </select>
    </div>
    )}
    
     
     
      <div className="data-donate-wrapp">
        <div id="all" className={`data-info__date ${activeButton !== 'all' && 'hidden'}`}>
          <div className="data-info__date-item">
            <p className="text-500">Hoy</p>
            <DonationsListComponent
            onItemClick={handleItemClick}

              numbers={2}
            />
          </div>
          <div className="data-info__date-item">
            <p className="text-500">Ayer</p>
            <DonationsListComponent
            onItemClick={handleItemClick}

              numbers={2}
            />
          </div>
          <div className="data-info__date-item">
            <p className="text-500">21/junio/2024</p>
            <DonationsListComponent
            onItemClick={handleItemClick}

              numbers={2}
            />
          </div>
        </div>
        <div id="today" className={`data-info__date ${activeButton !== 'today' && 'hidden'}`}>
          <div className="data-info__date-item">
            <p className="text-500">Hoy</p>
            <DonationsListComponent
            onItemClick={handleItemClick}

              numbers={7}
            />
          </div>
         
        </div>
        <div id="yesterday" className={`data-info__date ${activeButton !== 'yesterday' && 'hidden'}`}>
          <div className="data-info__date-item">
            <p className="text-500">Ayer</p>
            <DonationsListComponent
            onItemClick={handleItemClick}

              numbers={7}
            />
          </div>
        </div>
        <div id="7days" className={`data-info__date ${activeButton !== '7days' && 'hidden'}`}>
          <div className="data-info__date-item">
            <p className="text-500">7 días</p>
            <DonationsListComponent
            onItemClick={handleItemClick}

              numbers={7}
            />
          </div>
        </div>
        <div id="15days" className={`data-info__date ${activeButton !== '15days' && 'hidden'}`}>
          <div className="data-info__date-item">
            <p className="text-500">15 días</p>
            <DonationsListComponent
            onItemClick={handleItemClick}

              numbers={7}
            />
          </div>
        </div>
        <div id="30days" className={`data-info__date ${activeButton !== '30days' && 'hidden'}`}>
          <div className="data-info__date-item">
            <p className="text-500">30 días</p>
            <DonationsListComponent
            onItemClick={handleItemClick}

              numbers={7}
            />
          </div>
        </div>
        <div id="date"  className={`data-info__date ${activeButton !== 'date' && 'hidden'}`}>
          {calendarVisible &&
            <Calendar
            onChange={handleDateChange}
            value={value}
            tileDisabled={tileDisabled}
          />
          }
       
          {selectedDate && (
            <div id="calendar-set" className="data-info__date-item">
              <p className="text-500">
                {selectedDate.toLocaleDateString('es-ES', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                }).replace(/ de /g, '/')}
              </p>
              <DonationsListComponent
              onItemClick={handleItemClick}
               numbers={7} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DonationTabsComponent;