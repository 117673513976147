export const ADMIN_DATA = [
  {
    id: 1,
    name: "Rafaela",
    position: "Coordinadora",
  },
  {
    id: 2,
    name: "María",
    position: "Acompañante",
  },
  {
    id: 3,
    name: "Ana",
    position: "Tallerista",
  },
  {
    id: 4,
    name: "Laura",
    position: "Entregadora",
  },
  {
    id: 4,
    name: "Margó",
    position: "Administrador",
  }
]