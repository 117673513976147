export const ROLES_DATA = [
  {
    id: 1,
    value: 'admin',
    label: 'Admin',
  },
  {
    id: 2,
    value: 'lider-colectiva',
    label: 'Líder Colectiva',
  },
  {
    id: 3,
    value: 'acompanante',
    label: 'Acompañante',
  },
  {
    id: 4,
    value: 'tallerista',
    label: 'Tallerista',
  },
  {
    id: 5,
    value: 'entregadora',
    label: 'Entregadora',
  }
]