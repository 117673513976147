import React from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import Accordion from 'react-bootstrap/Accordion';
import { useState } from 'react';
import Calendar from 'react-calendar';
import TagsManagerComponent from '../../../../components/Forms/tagsManager/tagsManager.component';
import ColectivaTableComponent from '../../../../components/Elements/DataTable/colectivaTable.component';
import AdminCardProps from '../../../../components/Elements/AdminCard/adminCard.component';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const ColectivaScreen: React.FC = () => {
  const [value, setValue] = useState<Value>(new Date());

  const handleDateChange = (newValue: Value) => {
    setValue(newValue);
  };

  // Define los tipos para los parámetros de la función tileDisabled
  const tileDisabled = ({ date, view }: { date: Date; view: string }) => {
    const currentYear = new Date().getFullYear();
    if (view === 'year') {
      return date.getFullYear() < currentYear - 1;
    }
    return false;
  };

  return (
    <>
      <HeaderShared />
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom row">
            <div className="title-section module-bottom40 col-12">
              <h1 className="h2">Inicio</h1>
              <p className="h5">Colectiva Quintero, Valparaíso</p>
              <a href="#" className="btn btn--with-number">
                Aporte total $5,000 CLP{' '}
                <i className="icon icon--arrow-right-large"></i>
              </a>
            </div>
            <aside className="dashboard-aside col-md-3 col-12">
              <div className="dashboard-aside__inner">
                <h4 className="mb-4">Buscar</h4>
                <div className="form-row form--search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar"
                  />
                  <i className="icon icon--search-bar"></i>
                </div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Búsqueda avanzada </Accordion.Header>
                    <Accordion.Body>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Calendarios
                        </label>
                        <Accordion className="accordion-inner">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              Selecciona una fecha{' '}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Calendar
                                onChange={handleDateChange}
                                value={value}
                                tileDisabled={tileDisabled}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Etiquetas
                        </label>
                        <TagsManagerComponent admin={{}} user={{}} />
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">Roles</label>
                        <select
                          className="form-select form--small"
                          aria-label="Roles"
                          defaultValue={'default'}
                          name="rol"
                        >
                          <option value="default" disabled>
                            Selecciona un rol
                          </option>
                          <option value="rol-1">Rol 1</option>
                          <option value="rol-2">Rol 2</option>
                          <option value="rol-3">Rol 3</option>
                        </select>
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Estado
                        </label>
                        <select
                          className="form-select form--small"
                          aria-label="Estado"
                          defaultValue={'default'}
                          name="estado"
                        >
                          <option value="default" disabled>
                            Selecciona un estado
                          </option>
                          <option value="Registra">Registrada</option>
                          <option value="En taller">En taller</option>
                          <option value="Logrado">Logrado</option>
                          <option value="Seguimiento">Seguimiento</option>
                          <option value="En espera">En espera</option>
                        </select>
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Colectiva
                        </label>
                        <select
                          className="form-select form--small"
                          aria-label="Colectiva"
                          defaultValue={'default'}
                          name="colectiva"
                        >
                          <option value="default" disabled>
                            Selecciona una colectiva
                          </option>
                          <option value="colectiva-1">Colectiva 1</option>
                          <option value="colectiva-2">Colectiva 2</option>
                        </select>
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Taller
                        </label>
                        <select
                          className="form-select form--small"
                          aria-label="Taller"
                          defaultValue={'default'}
                          name="taller"
                        >
                          <option value="default" disabled>
                            Selecciona un taller
                          </option>
                          <option value="taller-1">Taller 1</option>
                          <option value="taller-2">Taller 2</option>
                        </select>
                      </div>
                      <div className="form-row">
                        <button
                          type="button"
                          className="btn btn--type1 btn--100"
                        >
                          Buscar
                        </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Chat interno</Accordion.Header>
                    <Accordion.Body>
                      <ul className="user-pic-selection">
                        <li>
                          <button type="button">
                            <figure>
                              <img
                                src="/assets/images/icons/user-admin.svg"
                                alt="User image"
                              />
                            </figure>
                          </button>
                        </li>
                        <li>
                          <button type="button">
                            <figure>
                              <img
                                src="/assets/images/icons/user-admin.svg"
                                alt="User image"
                              />
                            </figure>
                          </button>
                        </li>
                        <li>
                          <button type="button">
                            <figure>
                              <img
                                src="/assets/images/icons/user-admin.svg"
                                alt="User image"
                              />
                            </figure>
                          </button>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="col-12 col-reset pt-3 pb-4">
                  <a href="/nueva-amiga" className="btn btn--type1 btn--100">
                    Registrar nueva amiga
                  </a>
                </div>
                <div className="col-12 col-reset d-flex justify-content-center">
                  <button type="button" className="btn--danger">
                    Eliminar Colectiva
                  </button>
                </div>
              </div>
            </aside>
            <div className="dashboard-info col-md-9 col-12">
              <h4 className="mb-4">Mujeres que acompañas</h4>
              <ColectivaTableComponent />
              <div className="mt-4 user-info-tab">
                <h4 className="text-20 text-regular text-purple800">
                  Coordinadora de Colectiva
                </h4>
                <hr className="mt-0 hr-pink" />
                <div className="row">
                  <div className="user-info-tab__col col-md-4 col-sm-6 col-12">
                    <AdminCardProps
                      name="Valeria"
                      role="Coordinadora"
                      responseTime="Menos de 10 minutos"
                      url="/detalle-coordinadora"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 user-info-tab">
                <h4 className="text-20 text-regular text-purple800">
                  Acompañantes
                </h4>
                <hr className="mt-0 hr-pink" />
                <div className="row">
                  <div className="user-info-tab__col col-md-4 col-sm-6 col-12">
                    <AdminCardProps
                      name="Rafaela"
                      role="Acompañante"
                      responseTime="Menos de 10 minutos"
                      url="/detalle-acompanante"
                    />
                  </div>
                  <div className="user-info-tab__col col-md-4 col-sm-6 col-12">
                    <AdminCardProps
                      name="Ana"
                      role="Acompañante"
                      responseTime="Menos de 10 minutos"
                      url="/detalle-acompanante"
                    />
                  </div>
                  <div className="user-info-tab__col col-md-4 col-sm-6 col-12">
                    <AdminCardProps
                      name="María"
                      role="Acompañante"
                      responseTime="Menos de 10 minutos"
                      url="/detalle-acompanante"
                    />
                  </div>
                  <div className="user-info-tab__col col-md-4 col-sm-6 col-12">
                    <AdminCardProps
                      name="Lucy"
                      role="Acompañante"
                      responseTime="Menos de 10 minutos"
                      url="/detalle-acompanante"
                    />
                  </div>
                  <div className="user-info-tab__col col-md-4 col-sm-6 col-12">
                    <AdminCardProps
                      name="Mar"
                      role="Acompañante"
                      responseTime="Menos de 10 minutos"
                      url="/detalle-acompanante"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 user-info-tab">
                <h4 className="text-20 text-regular text-purple800">
                  Talleristas
                </h4>
                <hr className="mt-0 hr-pink" />
                <div className="row">
                  <div className="user-info-tab__col col-md-4 col-sm-6 col-12">
                    <AdminCardProps
                      name="Rita"
                      role="Tallerista"
                      responseTime="Menos de 10 minutos"
                      url="/detalle-tallerista"
                    />
                  </div>
                  <div className="user-info-tab__col col-md-4 col-sm-6 col-12">
                    <AdminCardProps
                      name="Victoria"
                      role="Tallerista"
                      responseTime="Menos de 10 minutos"
                      url="/detalle-tallerista"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 user-info-tab">
                <h4 className="text-20 text-regular text-purple800">
                  Entregadoras
                </h4>
                <hr className="mt-0 hr-pink" />
                <div className="row">
                  <div className="user-info-tab__col col-md-4 col-sm-6 col-12">
                    <AdminCardProps
                      name="Eugenia"
                      role="Entregadora"
                      responseTime="Menos de 10 minutos"
                      url="/detalle-entregadora"
                    />
                  </div>
                  <div className="user-info-tab__col col-md-4 col-sm-6 col-12">
                    <AdminCardProps
                      name="Simone"
                      role="Entregadora"
                      responseTime="Menos de 10 minutos"
                      url="/detalle-entregadora"
                    />
                  </div>
                  <div className="user-info-tab__col col-md-4 col-sm-6 col-12">
                    <AdminCardProps
                      name="Eva"
                      role="Entregadora"
                      responseTime="Menos de 10 minutos"
                      url="/detalle-entregadora"
                    />
                  </div>
                  <div className="user-info-tab__col col-md-4 col-sm-6 col-12">
                    <AdminCardProps
                      name="Mónica"
                      role="Entregadora"
                      responseTime="Menos de 10 minutos"
                      url="/detalle-entregadora"
                    />
                  </div>
                  <div className="user-info-tab__col col-md-4 col-sm-6 col-12">
                    <AdminCardProps
                      name="Yolanda"
                      role="Entregadora"
                      responseTime="Menos de 10 minutos"
                      url="/detalle-entregadora"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default ColectivaScreen;
