import React from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import UpdatePasswordComponent from '../../../components/Auth/UpdatePassword/updatePassword.component';


const UpdatePasswordScreen = () => {
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">

            <div className="auth-row module-top row">
              <div className="col-md-5 col-12">
                <h1>Actualiza tu contraseña</h1>
                <p>Crea una nueva contraseña, que recuerdes fácil y sólo tú conozcas.</p>
                <UpdatePasswordComponent />
              </div>
              <aside className="auto-row__right col-md-7 col-12">
                <figure className="image-400">
                  <img src="/assets/images/illustrations/women.png" alt="Women" />
                </figure>
              </aside>
            </div>
           
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
}

export default UpdatePasswordScreen;