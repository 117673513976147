import React from 'react';
import FooterShared from '../../../../shared/Header/footer.shared';
import HeaderAdminSearch from '../../../../shared/Header/headerAdminSearch.shared';
import DonationsInitComponent from '../../../../components/Elements/Donations/donationsInit.component';
import DonationsListComponent from '../../../../components/Elements/Donations/donationsList.component';
import WomenComponent from '../../../../components/Elements/Women/women.component';
import TalleresListDateComponent from '../../../../components/Elements/Talleres/talleresList.component';
import ColectivasListComponent from '../../../../components/Elements/Colectivas/colectivasList.component';
import { deleteSession } from '../../../../utils/helpers';

const AdminScreen: React.FC = () => {
  const handleLogOut = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    deleteSession();
    setTimeout(() => {
      window.location.href = '/';
    }, 100);
  };

  return (
    <section className="dashboard-index">
      <HeaderAdminSearch />
      <section className="dashboard-index__container container">
        <div className="row">
          <div className="dashboard-index__desktop col-xl-3 col-12">
            <section className="dashboard-card dashboard--1 dashboard--v100">
              <div className="dashboard-card__inner">
                <div className="dashboard-card__inner-content">
                  <div className="d-flex justify-content-center">
                    <figure className="logo-init">
                      <img
                        src="/assets/images/logo/salud-logo-white.png"
                        alt="Red Salud Comunitaria"
                      />
                    </figure>
                  </div>
                  <div className="dashboard-card__buttons">
                    <ul>
                      <li>
                        <a href="/colectivas" className="btn btn--type5">
                          <i className="icon icon--colectivas"></i>
                          Colectivas
                        </a>
                      </li>
                      <li>
                        <a
                          href="/equipo-de-las-amigas"
                          className="btn btn--type5"
                        >
                          <i className="icon icon--equipo"></i>
                          Equipo Amigas
                        </a>
                      </li>
                      <li>
                        <a href="/mujeres" className="btn btn--type5">
                          <i className="icon icon--mujeres"></i>
                          Mujeres
                        </a>
                      </li>
                      <li>
                        <a href="/documentos" className="btn btn--type5">
                          <i className="icon icon--donativos"></i>
                          Documentos
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <a
                  href="/"
                  onClick={(event) => handleLogOut(event)}
                  className="btn btn--logout"
                >
                  <i className="icon icon--logout"></i> Cerrar sesión
                </a>
              </div>
            </section>
          </div>
          <div className="dashboard-index__middle col-xl-4 col-12">
            <section className="dashboard-card dashboard--3 dashboard--donate mb-4">
              <h2 className="h4">Documentos</h2>
              <DonationsInitComponent
                number={50100.5}
                date="20 de septiembre 2021"
                size="large"
                clp={true}
              />
              <div className="donations-list">
                <p className="text-500">Últimos documentos recibidos </p>
                <DonationsListComponent numbers={3} />
                <a href="#" className="btn btn--type1 btn--100">
                  Ver detalle de documentos
                </a>
              </div>
            </section>
            <section className="dashboard-card dashboard--2">
              <WomenComponent />
              <a href="/mujeres" className="btn btn--type2 btn--100">
                Ver registro de mujeres
              </a>
            </section>
          </div>
          <div className="col-xl-4 col-12">
            <section className="dashboard-card dashboard--4 mb-4">
              <h2 className="h4">Próximo Talleres</h2>
              <TalleresListDateComponent />
              <a href="/talleres" className="btn btn--type2 btn--100">
                Ver todos los talleres
              </a>
            </section>
            <section className="dashboard-card dashboard--4">
              <h2 className="h4">Colectivas</h2>
              <ColectivasListComponent />
              <a href="/colectivas" className="btn btn--type1 btn--100">
                Ver todas las colectivas
              </a>
            </section>
          </div>
        </div>
      </section>
      <FooterShared />
    </section>
  );
};

export default AdminScreen;
