import React from 'react';
import { Modal} from 'react-bootstrap';

interface ModalAsignarRolSuccessComponentProps {
  show: boolean;
  onHide: () => void;
}

const ModalAsignarRolSuccessComponent: React.FC<ModalAsignarRolSuccessComponentProps> = ({ show, onHide }) => {
  
 

  return (
    <>
      <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal--gray"
    >
      <Modal.Header className="justifify-content-center">
        <Modal.Title id="contained-modal-title-vcenter" className="col-12">
          <h2 className="h3 text-center">Rol asignado con éxito</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div className="col-12 d-flex justify-content-center">
            <figure className="image-280">
              <img src="/assets/images/illustrations/remember.png" alt="Success" />
            </figure>
          </div>
          <p className="text-center mb-0">Se asigno el rol <strong>TALLERISTA</strong> al usuario <strong>RAFAELA</strong></p>
          <p className="text-center mb-0">Los cambios ya podrán ser visibles en su perfil</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          <button type="submit" className="btn btn--type1 btn--290" onClick={onHide}>Regresar a perfil</button>
        </div>
       
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default  ModalAsignarRolSuccessComponent;