import React, { useState, useRef, useEffect } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ModalAsignarSuccessComponent from '../Modal/ModalAsignar/modalAsignarSuccess.component';
import ModalCambiarColectivaComponent from '../Modal/ModalCambiarColectiva/modalCambiar.component';
import ModalCambiarColectivaSuccessComponent from '../Modal/ModalCambiarColectiva/modalCambiarSuccess.component';
import ModalDisabledComponent from '../Modal/ModalDisabled/modalDisabled.component';
import ModalDisabledSuccessComponent from '../Modal/ModalDisabled/modalDisabledSuccess.component';
import ModalDeleteComponent from '../Modal/ModalDelete/modalDelete.component';
import ModalDeleteSuccessComponent from '../Modal/ModalDelete/modalDeleteSuccess.component';
import { IUser, UserStatus, UserSteps } from '../../../types/userInterface';

type ActionsButtonComponentProps = {
  user: Partial<IUser>;
  setModalShowAsignar: (show: boolean) => void;
  updateAmiga: (user: Partial<IUser>) => Promise<void>;
  setError: (message: string) => void;
};

const ActionsButtonComponent: React.FC<ActionsButtonComponentProps> = ({
  user,
  setModalShowAsignar,
  updateAmiga,
  setError,
}) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const navigate = useNavigate();
  const [modalShowAsignarSuccess, setModalShowAsignarSuccess] = useState(false);
  const [modalShowCambiar, setModalShowCambiar] = React.useState(false);
  const [modalShowCambiarSuccess, setModalShowCambiarSuccess] = useState(false);
  const [modalShowDisabled, setModalShowDisabled] = React.useState(false);
  const [modalShowDisabledSuccess, setModalShowDisabledSuccess] =
    useState(false);
  const [modalShowDelete, setModalShowDelete] = React.useState(false);
  const [modalShowDeleteSuccess, setModalShowDeleteSuccess] = useState(false);
  const [personalData, setPersonalData] = useState<Partial<IUser>>({});

  const handleClose = () => setShow(false);

  // Asignar
  const openModalAsignar = () => {
    setModalShowAsignar(true);
    setShow(false);
  };

  const handleModalAsignarSuccessClose = () => {
    setModalShowAsignarSuccess(false);
    setShow(false);
  };

  // Cambiar
  const openModalCambiar = () => {
    setModalShowCambiar(true);
    setShow(false);
  };

  const handleModalCambiarSuccess = (user: Partial<IUser>) => {
    updateAmiga(user)
      .then(() => {
        setModalShowCambiarSuccess(true);
        setModalShowCambiar(false);
        setPersonalData({
          ...personalData,
          ...user,
        });
      })
      .catch((error) => {
        setError(error);
      });
    setShow(false);
  };

  const handleModalCambiarClose = () => {
    setModalShowCambiar(false);
  };

  const handleModalCambiarSuccessClose = () => {
    setModalShowCambiarSuccess(false);
    setShow(false);
  };

  // Disabled
  const openModalDisabled = () => {
    setModalShowDisabled(true);
    setShow(false);
  };

  const handleModalDisabledSuccess = (user: Partial<IUser>) => {
    updateAmiga(user)
      .then(() => {
        setModalShowDisabledSuccess(true);
        setModalShowDisabled(false);
      })
      .catch((error) => {
        setError(error);
      });
    setShow(false);
  };

  const handleModalDisabledClose = () => {
    setModalShowDisabled(false);
  };

  const handleModalDisabledSuccessClose = () => {
    setModalShowDisabledSuccess(false);
    setShow(false);
  };

  // Delete
  const openModalDelete = () => {
    setModalShowDelete(true);
    setShow(false);
  };

  const handleModalDeleteSuccess = () => {
    updateAmiga({
      _id: user._id,
      status: UserStatus.BLOCKED,
    })
      .then(() => {
        setModalShowDeleteSuccess(true);
        setModalShowDelete(false);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setTimeout(() => {
          navigate('/mi-colectiva');
        }, 3000);
      });
  };

  const handleModalDeleteClose = () => {
    setModalShowDelete(false);
  };

  const handleModalDeleteSuccessClose = () => {
    setModalShowDeleteSuccess(false);
    setShow(false);
  };

  const handleOverlayClose = () => {
    handleClose();
  };

  useEffect(() => {
    if (user) {
      setPersonalData(user);
    }
  }, [user]);

  return (
    <>
      <ModalAsignarSuccessComponent
        show={modalShowAsignarSuccess}
        onHide={handleModalAsignarSuccessClose}
      />
      <ModalCambiarColectivaComponent
        show={modalShowCambiar}
        onHide={handleModalCambiarClose}
        onSuccess={handleModalCambiarSuccess}
        user={personalData}
      />
      <ModalCambiarColectivaSuccessComponent
        show={modalShowCambiarSuccess}
        onHide={handleModalCambiarSuccessClose}
        user={personalData}
      />
      <ModalDisabledComponent
        show={modalShowDisabled}
        onHide={handleModalDisabledClose}
        onSuccess={handleModalDisabledSuccess}
        user={personalData}
      />
      <ModalDisabledSuccessComponent
        show={modalShowDisabledSuccess}
        onHide={handleModalDisabledSuccessClose}
        user={personalData}
      />
      <ModalDeleteComponent
        show={modalShowDelete}
        onHide={handleModalDeleteClose}
        onSuccess={handleModalDeleteSuccess}
      />
      <ModalDeleteSuccessComponent
        show={modalShowDeleteSuccess}
        onHide={handleModalDeleteSuccessClose}
      />
      <button
        type="button"
        className="btn btn--type3 btn-270"
        ref={target}
        onClick={() => setShow(!show)}
      >
        + Más acciones
      </button>
      <Overlay
        target={target.current}
        show={show}
        placement={window.innerWidth < 768 ? 'bottom' : 'left'}
        rootClose
        onHide={handleOverlayClose}
      >
        <Tooltip className="tooltip--white">
          <ul className="tooltip-options">
            {personalData.step === UserSteps.MI_PROCESO && (
              <li>
                <button type="button" onClick={openModalAsignar}>
                  <i className="icon icon--user-single"></i>
                  Asignar acompañante
                </button>
              </li>
            )}
            <li>
              <Link to={`/editar-usuario/${personalData._id}`}>
                <i className="icon icon--edit"></i>
                Editar información
              </Link>
            </li>
            <li>
              <button type="button" onClick={openModalCambiar}>
                <i className="icon icon--change"></i>
                Cambiar a otra colectiva
              </button>
            </li>
            {personalData.status !== UserStatus.INACTIVE && (
              <li>
                <button type="button" onClick={openModalDisabled}>
                  <i className="icon icon--remove"></i>
                  Desactivar cuenta
                </button>
              </li>
            )}
            {personalData.status === UserStatus.INACTIVE && (
              <li>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    updateAmiga({
                      _id: personalData._id,
                      status: UserStatus.ACTIVE,
                    })
                      .then(() => {
                        setModalShowDisabledSuccess(true);
                        setModalShowDisabled(false);
                      })
                      .catch((error) => {
                        setError(error);
                      });
                  }}
                >
                  <i className="icon icon--change"></i>
                  Activar cuenta
                </button>
              </li>
            )}
            <li>
              <button type="button" onClick={openModalDelete}>
                <i className="icon icon--delete"></i>
                <span className="text-red">Eliminar cuenta</span>
              </button>
            </li>
          </ul>
        </Tooltip>
      </Overlay>
    </>
  );
};

export default ActionsButtonComponent;
