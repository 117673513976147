import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import LoaderComponent from '../../../../components/Loader/loader.component';
import { getSession } from '../../../../utils/helpers';
import { getWorkshop } from '../../../../api/workshopApi';
import { IWorkshop } from '../../../../types/workshopInterface';
import { format } from 'date-fns';

const TallerCreateSuccessScreen: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [workshop, setWorkshop] = useState<Partial<IWorkshop>>({});

  const { id } = useParams<{ id: string }>();

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    }).then(() => {
      navigate('/talleres');
    });
  };

  const fetchWorkshop = async () => {
    try {
      setLoading(true);
      if (!id) {
        throw new Error('No se pudo obtener el id del taller');
      }
      const session = getSession();
      const response = await getWorkshop(id, session?.token || '');
      setWorkshop(response);
    } catch (error) {
      setError('Ocurrió un error al obtener los datos del taller');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchWorkshop();
  }, []);

  return (
    <>
      <HeaderShared />
      {loading && <LoaderComponent />}
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-lg-6 col-12 mx-auto text-center">
                <div className="d-flex justify-content-center">
                  <figure className="image-490">
                    <img
                      src="/assets/images/illustrations/cat.png"
                      alt="Confirmación"
                    />
                  </figure>
                </div>
                <h1 className="text-40 mb-3">¡ Nuevo taller creado !</h1>
                <p>
                  Se registró un nuevo taller{' '}
                  <strong>{`${workshop.name || ''}`}</strong> para el{' '}
                  {workshop.workshopDate && (
                    <strong>
                      {format(
                        new Date(workshop.workshopDate || ''),
                        'EEEE dd MMMM, yyyy '
                      )}
                    </strong>
                  )}
                  de manera exitosa, las mujeres ya se podrán inscribir.
                </p>
              </div>
            </div>
            <div className="form-row">
              <div className="col-12 mb-4 d-flex justify-content-center">
                <a href="/talleres" className="btn btn--type1 btn--370">
                  Ir a talleres
                </a>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <a href="/" className="btn btn--type2 btn--370">
                  Ir al chat del taller
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default TallerCreateSuccessScreen;
