import React from 'react';

interface StarScoreComponentProps {
 type: string;
 score: number;
}

const StarScore: React.FC<StarScoreComponentProps> = ({type, score}) => {
  const solidStarsCount = Math.min(score, 5); // Limita el número máximo de estrellas sólidas a 5
  const regularStarsCount = Math.max(5 - solidStarsCount, 0); // Calcula el número de estrellas regulares

  const solidStars = Array(solidStarsCount).fill(null).map((_, index) => (
    <li key={index}>
      <i className="fa-solid fa-star"></i>
    </li>
  ));

  const regularStars = Array(regularStarsCount).fill(null).map((_, index) => (
    <li key={index}>
      <i className="fa-regular fa-star"></i>
    </li>
  ));

  return (
    <div className="quality-score-star">
      <p className="mb-0"><strong>{type}</strong></p>
      <ul>
        {solidStars}
        {regularStars}
      </ul>
    </div>
  );
};

export default StarScore;