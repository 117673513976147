export const ZONES_DATA = [
  {
    "zone": "Zona Norte",
    "colectivas": [
      {
        "name": "Región de Arica y Parinacota",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Región de Tarapacá",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Región de Antogasta",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Región de Atacama",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Región de Coquimbo",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
    ]
  },
  {
    "zone": "Zona central",
    "colectivas": [
      {
        "name": "Valparaíso",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Petorca",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Quillota",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Marga Marga",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "San Antonio",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Rapa Nui",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Los Andes",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "San Felipe",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Chacabuco",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Metropolitana",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Ohiggins",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Maule",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Ohiggins, Costa",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
    ]
  },
  {
    "zone": "Zona Sur",
    "colectivas": [
      {
        "name": "Biobío",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Ñuble",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Araucanía",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Los Ríos",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Osorno",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Llanquihue",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Palena",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Chiloé",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
    ]
  },
  {
    "zone": "Zona Austral",
    "colectivas": [
      {
        "name": "Aysén",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Magallanes",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Tierra del Fuego",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      {
        "name": "Última Esperanza",
        "women": 100,
        "lider": "Valeria",
        "acompanantes": "Rafaela, Ana, María, Lucy, Mar",
        "talleristas": "Rita, Victoria, Mariana, Alejandra",
        "entregadoras": "Eugenia, Simona, Eva, Mónica",
        "aporte": 5000,
        "url": "/mi-colectiva"
      },
      
    ]
  },
]