import React from 'react';
import ColectivaItemComponent from '../Buttons/colectivaItem.component';
import {COLECTIVAS_DATA} from '../../../utils/colectivas';

interface ColectivasListComponentProps {
}

const ColectivasListComponent: React.FC<ColectivasListComponentProps> = () => {

  return (
    <div className="colectiva-list-data">
      <ul>
        {COLECTIVAS_DATA.slice(0, 3).map((colectiva, index) => (
          <li key={index}>
            <ColectivaItemComponent
              colectiva={colectiva.title}
              organizer={colectiva.organizer}
              amount={colectiva.number}
              quantity={colectiva.currency}
              url={colectiva.url}
            />
          </li>
        ))}
        <li>
          
        </li>
      </ul>
    </div>
  );
};

export default ColectivasListComponent;