import React from 'react';

type DonationsItemComponentProps = {
  label: string;
  amount: number;
  onClick?: () => void;
};

const DonationsItemComponent: React.FC<DonationsItemComponentProps> = ({label, amount, onClick}) => {
  const formattedAmount = amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  // Función para manejar el clic en el enlace
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); // Previene el redirect
    if (onClick) {
      onClick(); // Llama al callback onClick si está definido
    }
  };


  return (
    <a href="/" className="btn btn--list" onClick={handleClick}>
      <div>
        <i className="icon icon--coin"></i>
        <span className="btn--list__label text-400">{label}</span>
      </div>
      <span className="btn--list__number">${formattedAmount} <i className="icon icon--arrow-right"></i></span>
    </a>
  );
};

export default DonationsItemComponent;