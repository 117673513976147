import React from 'react';

interface AdminCardProps {
  name: string; // Nombre del usuario
  role: string; // Rol del usuario
  responseTime?: string; // Tiempo de respuesta del usuario
  url?: string;
  title?: string;
}

const AdminCard: React.FC<AdminCardProps> = ({ name, role, responseTime, url, title }) => {
  return (
    <div className="user-card user--hover">
      {url &&  <a href={url} className="user-card__url"></a>}
      <div className="chatbox-action__user">
        <figure>
          <img src="/assets/images/icons/user-pic-2.svg" alt={name} />
        </figure>
        <article>
          {title == 'h4' ? <h4>{name}</h4> : <h2>{name}</h2>}
          <p className="text-20">{role}</p>
        </article>
      </div>
      {responseTime && 
        <div className="user-card__time">
          <p className="mb-0 text-purple800"><strong>Mi tiempo de respuesta</strong></p>
          <p className="mb-0 text-icon"><i className="icon icon--clock"></i> {responseTime}</p>
        </div>
      }
    </div>
  );
};

export default AdminCard;
