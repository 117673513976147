import React, { useState, useEffect } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import CustomPagination from '../Paginator/paginator.component';
import { Accordion } from 'react-bootstrap';
import { IUser } from '../../../types/userInterface';
import { IAdmin } from '../../../types/adminInterface';

interface TallerTableProps {
  defaultRowsToShow?: number; // TODO vista rápida
  attenders?: IUser[];
  assignedToList?: IAdmin[];
  editAsistentes?: boolean;
  terminado?: boolean;
  handleUserDataChange?: (user: IUser) => void;
}

const TallerTableComponent: React.FC<TallerTableProps> = ({
  attenders = [],
  assignedToList = [],
  editAsistentes = false,
  terminado = false,
  handleUserDataChange,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1200);

  const columns: TableColumn<IUser>[] = [
    {
      name: 'No. Ficha',
      selector: (row) => row.userId,
    },
    {
      name: 'Nombre',
      selector: (row) => row.username,
      format: (row) => {
        return (
          <a
            href={`/detalle-usuario/${row._id}`}
            className="text-purple500 text-uppercase new"
          >
            <strong>{row.username}</strong>
          </a>
        );
      },
    },
    {
      name: 'Semanas',
      selector: (row) =>
        row.saludGinecologica.pregnancyCalculator?.weeks ||
        '' + row.saludGinecologica.pregnancyCalculator?.days ||
        '',
      format: (row) => formatSemanas(row),
    },
    {
      name: 'Derivada de',
      selector: (row) => row.derivada.derivadaA || 'N/A',
    },
    {
      name: 'Acompañante',
      selector: (row) => {
        const assignedTo = assignedToList.find(
          (admin) => admin._id === row.assignedTo?.adminId
        );
        return assignedTo ? assignedTo.username : 'Sin asignar';
      },
    },
    {
      name: 'Apuntes',
      selector: (row) => row.apuntes || '',
      format: (row) => formatApuntes(row),
    },
    {
      name: 'Estado',
      selector: (row) => row.workshopStatus || '',
      format: (row) => formatStatus(row, row.workshopStatus || ''),
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const formatSemanas = (user: IUser) => {
    const { pregnancyCalculator } = user.saludGinecologica;
    const weeks = pregnancyCalculator?.weeks || '0';
    const days = pregnancyCalculator?.days || '0';
    if (editAsistentes) {
      return (
        <div className="data-table__input">
          <input
            type="number"
            className="form-control form--medium form--center"
            defaultValue={weeks}
            onChange={(e) => {
              handleUserDataChange?.({
                ...user,
                saludGinecologica: {
                  ...user.saludGinecologica,
                  pregnancyCalculator: {
                    ...user.saludGinecologica.pregnancyCalculator,
                    weeks: parseInt(e.target.value),
                  },
                },
              });
            }}
          />
          <p>+</p>
          <input
            type="number"
            className="form-control form--medium form--center"
            defaultValue={days}
            onChange={(e) => {
              handleUserDataChange?.({
                ...user,
                saludGinecologica: {
                  ...user.saludGinecologica,
                  pregnancyCalculator: {
                    ...user.saludGinecologica.pregnancyCalculator,
                    days: parseInt(e.target.value),
                  },
                },
              });
            }}
          />
          <p>días</p>
        </div>
      );
    }
    return `${weeks} + ${days} días`;
  };

  const formatApuntes = (user: IUser) => {
    if (editAsistentes) {
      return (
        <select
          className="form-control form-select form--medium"
          defaultValue={user.apuntes}
          value={user.apuntes || ''}
          onChange={(e) => {
            handleUserDataChange?.({ ...user, apuntes: e.target.value || '' });
          }}
        >
          <option value=""> - </option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
      );
    }
    if (terminado) {
      if (user.apuntes != null && user.apuntes != '') {
        return (
          <span className="data-table__number disabled">{user.apuntes}</span>
        );
      } else {
        return <span className="data-table__number number--fail">No dio</span>;
      }
    }
    if (!user.apuntes) {
      return <span className="data-table__number number--wait">PENDIENTE</span>;
    } else {
      return <span className="data-table__number">{user.apuntes}</span>;
    }
  };

  const formatStatus = (user: IUser, status: string) => {
    if (editAsistentes) {
      return (
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value={user.status}
            id={`estado-${user._id}`}
            checked={user.workshopStatus === 'attended'}
            onChange={(e) => {
              const checked = e.target.checked;
              const status = checked ? 'attended' : 'notAttended';
              handleUserDataChange?.({ ...user, workshopStatus: status });
            }}
          />
          <label className="form-check-label" htmlFor={`estado-${user._id}`}>
            {user.workshopStatus === 'attended' ? 'Asistió' : 'No Asistió'}
          </label>
        </div>
      );
    }
    if (terminado) {
      switch (status) {
        case 'attended':
          return (
            <span className="text-icon text-gray600">
              <span className="material-icons-outlined text-16 mr16">done</span>{' '}
              Asistió
            </span>
          );
        case 'notAttended':
          return (
            <span className="text-icon text-red">
              <span className="material-icons-outlined text-16 mr16">
                close
              </span>{' '}
              No asistió
            </span>
          );
        default:
          return (
            <span className="text-icon text-red">
              <span className="material-icons-outlined text-16 mr16">
                close
              </span>{' '}
              No asistió
            </span>
          );
      }
    }
    switch (status) {
      case 'pending':
        return (
          <span className="badge badge--status badge--status-registrada">
            Invitada
          </span>
        );
      case 'hold':
        return (
          <span className="badge badge--status badge--status-info">
            En espera
          </span>
        );
      case 'accepted':
        return (
          <span className="badge badge--status badge--status-taller">
            Registrada
          </span>
        );
      case 'rejected':
        return (
          <span className="badge badge--status badge--status-info">
            No logrado
          </span>
        );
      case 'attended':
        return (
          <span className="badge badge--status badge--status-acompanada">
            Atendió
          </span>
        );
      default:
        return (
          <span className="badge badge--status badge--status-info">
            {status}
          </span>
        );
    }
  };

  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="data-table">
      {!isMobileView ? (
        <DataTable
          columns={columns}
          data={attenders}
          pagination={false}
          paginationComponentOptions={paginationComponentOptions}
          paginationComponent={(props) => {
            const handleChangePage = (page: number) => {
              console.log('Changing page to', page);
              (props.onChangePage as (page: number) => void)(page);
            };
            return (
              <div className="data-paginator">
                <CustomPagination
                  rowsPerPage={props.rowsPerPage}
                  rowCount={props.rowCount}
                  onChangePage={handleChangePage}
                  currentPage={currentPage}
                />
              </div>
            );
          }}
          onChangePage={(page) => setCurrentPage(page)}
        />
      ) : (
        <section className="data-table-mobile">
          <div className="data-table-mobile__header">
            <p>
              <strong>Nombre</strong>
            </p>
          </div>
          <Accordion className="data-table-mobile__accordion">
            {attenders.map((row, index) => {
              const assignedTo = assignedToList.find(
                (admin) => admin._id === row.assignedTo?.adminId
              );
              return (
                <Accordion.Item eventKey={String(index)} key={row._id}>
                  <Accordion.Header>{row.username}</Accordion.Header>
                  <Accordion.Body>
                    <table>
                      <tr>
                        <td>
                          <strong>No. Ficha:</strong>
                        </td>
                        <td className="text-center">{row.userId}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Semanas:</strong>
                        </td>
                        <td className="text-center">{formatSemanas(row)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Derivada de:</strong>
                        </td>
                        <td className="text-center">
                          {row.derivada.derivadaA || 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Acompañante:</strong>
                        </td>
                        <td className="text-center">
                          {assignedTo?.username || 'Sin asignar'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Apuntes:</strong>
                        </td>
                        <td className="text-center">{formatApuntes(row)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Estado:</strong>
                        </td>
                        <td className="text-center">
                          {formatStatus(row, row.workshopStatus || '')}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="text-center">
                          <a href="/detalle-usuario" className="btn btn--type2">
                            <strong>Ver ficha</strong>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </section>
      )}
    </div>
  );
};

export default TallerTableComponent;
