import axios from 'axios';
import qs from 'qs';
import { strapiDoc, strapiDocPaginate } from '../types/strapiDocs';

const strapi = axios.create({
  baseURL: 'https://cms.conlasamigas.org/api',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer 28209adfe6ff7fecb055e70b396a53950fc26cd10e4450b8eec93bf543ffbf686976a64fa1fc53880b4d54bd6322325a34b7098fce7ecaf78d23c18cba3e695d25fca307ca2e4cb0db3729d51f6900b2ed433f9ee2fede48820e5583490d95284bae6d20c2a8ce73306d21921c8e1382130228d0d444b0d59f14d9a1f1183d50`,
  },
});

const fetchStrapiDocs = async (route: string, params: object) => {
  const query = qs.stringify(params);
  const response = await strapi.get<strapiDocPaginate>(`${route}?${query}`);
  return response.data;
};

const fetchStrapiSingle = async (route: string, params: object) => {
  const query = qs.stringify(params);
  const response = await strapi.get<{
    data: strapiDoc;
  }>(`${route}?${query}`);
  return response.data;
};

export { fetchStrapiDocs, fetchStrapiSingle };
