import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteSession, getSession } from '../../utils/helpers';
import { IAdmin } from '../../types/adminInterface';

const HeaderAdminShared: React.FC = () => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [isOpen, setIsOpen] = useState(false);
  const [isLogged, setIsLogged] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(!isOpen); // Cambia el estado al opuesto de lo que está actualmente
  };
  const location = useLocation();

  const isActive = (path: string): string => {
    return location.pathname === path ? 'active' : '';
  };

  useEffect(() => {
    const session = getSession();
    if (session) {
      setIsLogged(true);
      setAdmin(session);
    }
  }, []);

  const homeNavigate = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    const { principalRol } = admin;
    console.log('principalRol', admin);
    switch (principalRol) {
      case 'Admin':
        navigate('/inicio-administrador');
        break;
      case 'Tallerista':
        navigate('/inicio-tallerista');
        break;
      case 'Acompañante':
        navigate('/inicio-acompanante');
        break;
      default:
        navigate('/');
        break;
    }
  };

  const handleLogOut = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    deleteSession();
    setTimeout(() => {
      window.location.href = '/';
    }, 100);
  };

  return (
    <header>
      <div className="container">
        <div className="header__col col-12">
          <a className="header__logo" onClick={homeNavigate}>
            <figure>
              <img
                src={`${
                  isOpen
                    ? '/assets/images/logo/salud-logo-white.png'
                    : '/assets/images/logo/salud-logo.png'
                }`}
                alt="Red de Salud Comunitaria"
              />
            </figure>
          </a>
          <nav className={`main-nav ${isOpen ? 'active' : ''}`}>
            <ul>
              {isLogged ? (
                <>
                  <li>
                    <a href="/colectivas" className={isActive('/colectivas')}>
                      Colectivas
                    </a>
                  </li>
                  <li>
                    <a
                      href="/equipo-de-las-amigas"
                      className={isActive('/equipo-de-las-amigas')}
                    >
                      Equipo Amigas
                    </a>
                  </li>
                  <li>
                    <a href="/mujeres" className={isActive('/mujeres')}>
                      Mujeres
                    </a>
                  </li>
                  <li>
                    <a href="/documentos" className={isActive('/documentos')}>
                      Documentos
                    </a>
                  </li>
                  <li className="main-nav__mobile">
                    <a
                      href="/"
                      onClick={(event) => handleLogOut(event)}
                      className={isActive('/')}
                    >
                      <strong>Cerrar sesión</strong>
                    </a>
                  </li>
                </>
              ) : (
                <li>
                  <a href="/" className={isActive('/')}>
                    Iniciar sesión
                  </a>
                </li>
              )}
            </ul>
          </nav>
          {isLogged ? (
            <div className="header__options header--admin">
              <div className="user-control">
                <button type="button" className="user-control-button">
                  <i className="icon icon--superadmin"></i>
                </button>
                <div className="user-options">
                  <div className="user-options__top">
                    <i className="icon icon--user-big"></i>
                    <article>
                      <h3 className="text-regular text-20">{`${
                        admin.username || ''
                      }`}</h3>
                      <a href="/mi-perfil">Ver perfil</a>
                    </article>
                  </div>
                  <div className="user-options__bottom">
                    <ul>
                      <li>
                        <a href="/" onClick={(event) => handleLogOut(event)}>
                          <i className="icon icon--logout"></i>
                          <span>Cerrar sesión</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="form-row form--search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar entre las amigas..."
                />
                <i className="icon icon--search-bar"></i>
              </div>
              <a href="/" className="icon-header ">
                <i className="icon icon--message"></i>
              </a>
              <a href="/nueva-amiga" className="icon-header ">
                <i className="icon icon--add-user"></i>
              </a>
            </div>
          ) : (
            ''
          )}
          <button
            type="button"
            className={`open-nav ${isOpen ? 'active' : ''}`}
            onClick={handleButtonClick}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderAdminShared;
