import React from 'react';

type Tag = {
  className: string;
  icon?: string;
  text: string;
};

type TagsComponentProps = {
  tags: Tag[];
};

const TagsComponent: React.FC<TagsComponentProps> = ({ tags }) => {
  return (
    <div className="view-detail__tags">
      {tags.map((tag, index) => (
        <div key={index} className={`badge badge--status ${tag.className}`}>
          {tag.icon && <span className="material-icons-outlined">{tag.icon}</span>}
          {tag.text}
        </div>
      ))}
    </div>
  );
};

export default TagsComponent;
