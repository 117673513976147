import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IUser } from '../../../types/userInterface';

interface InfoDataProps {
  user: Partial<IUser>;
  readonly?: boolean;
  setUser?: React.Dispatch<React.SetStateAction<Partial<IUser>>>;
}

const InfoDataComponent: React.FC<InfoDataProps> = ({ user, setUser }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    const newData = {
      [name]: value,
    };
    if (setUser) {
      setUser((prevUser) => ({
        ...prevUser,
        contextoSociocultural: {
          ...prevUser.contextoSociocultural,
          ...newData,
        },
      }));
    }
  };

  return (
    <div className="form-block">
      <form>
        <div className="form-row">
          <label htmlFor="ocupacion-1" className="form-label label--icon">
            Ocupación 1
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Ocupación del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="ocupacion-1"
            placeholder="Ocupación 1"
            name="principalJob"
            value={user.contextoSociocultural?.principalJob || ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="ocupacion-2" className="form-label label--icon">
            Ocupación 2
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Ocupación del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="ocupacion-2"
            placeholder="Ocupación 2"
            name="secondaryJob"
            value={user.contextoSociocultural?.secondaryJob || ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="ocupacion-3" className="form-label label--icon">
            Ocupación 3
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Ocupación del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="ocupacion-3"
            placeholder="Ocupación 3"
            name="additionalJob"
            value={user.contextoSociocultural?.additionalJob || ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="nivel-educativo" className="form-label label--icon">
            Nivel educativo
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Nivel educativo del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="nivel-educativo"
            placeholder="Nivel educativo"
            name="educationLevel"
            value={user.contextoSociocultural?.educationLevel || ''}
            onChange={handleInputChange}
          />
        </div>
        {user.contextoSociocultural?.isOriginTown && (
          <div className="form-row">
            <label htmlFor="nivel-educativo" className="form-label label--icon">
              Pueblo originario
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Pueblo originario del usuario</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="text"
              className="form-control"
              id="nivel-educativo"
              placeholder="Pueblo originario"
              name="originTown"
              value={user.contextoSociocultural?.originTown || ''}
              onChange={handleInputChange}
            />
          </div>
        )}
        <div className="form-row">
          <label htmlFor="nivel-educativo" className="form-label label--icon">
            Origen Afrodescendiente
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Origen Afrodescendiente del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="nivel-educativo"
            placeholder="Origen Afrodescendiente"
            name="afroDescendant"
            value={user.contextoSociocultural?.afroDescendant}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="vive-con" className="form-label label--icon">
            Vive con
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Vive con</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="vive-con"
            placeholder="Vive con"
            name="liveWith"
            value={user.contextoSociocultural?.liveWith}
            onChange={handleInputChange}
          />
        </div>
      </form>
    </div>
  );
};

export default InfoDataComponent;
