export const COORDINADORAS_DATA = [
  {
    id: 1,
    name: "Rafaela",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 2,
    name: "María",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 3,
    name: "Ana",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 4,
    name: "Laura",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 4,
    name: "Margó",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 5,
    name: "Isabella",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 6,
    name: "Sofia",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 7,
    name: "Valentina",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 8,
    name: "Camila",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 9,
    name: "Valeria",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 10,
    name: "Luciana",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 11,
    name: "Emma",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 12,
    name: "Mia",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 13,
    name: "Luna",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 14,
    name: "Martina",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 15,
    name: "Catalina",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 16,
    name: "Victoria",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 17,
    name: "Antonella",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 18,
    name: "Julieta",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 19,
    name: "Agustina",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 20,
    name: "Delfina",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 21,
    name: "Bianca",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 22,
    name: "Renata",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 23,
    name: "Abril",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  },
  {
    id: 24,
    name: "Emilia",
    position: "Coordinadora",
    url: "/detalle-coordinadora"
  }
]