import axios from 'axios';
import { IUserChat, IUserChatPaginate } from '../types/adminInterface';

const chatApi = axios.create({
  baseURL: 'https://chat.conlasamigas.org',
  headers: {
    'Content-Type': 'application/json',
  },
});

const fetchChatMessages = async (roomId: string, token: string) => {
  try {
    const response = await chatApi.get<IUserChatPaginate>('/messages-admin', {
      params: { roomId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching chat messages:', error);
    throw error;
  }
};

const publishMessage = async (body: IUserChat, token: string) => {
  try {
    const response = await chatApi.post(
      '/messages-admin',
      { ...body },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error publishing message:', error);
    throw error;
  }
};

export { fetchChatMessages, publishMessage };
