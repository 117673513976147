import React from 'react';
import { Accordion } from 'react-bootstrap';

interface ColectivaData {
  name: string;
  women?: number;
  lider?: string;
  acompanantes?: string;
  talleristas?: string;
  entregadoras?: string;
  aporte?: number;
  url?: string;
}

interface AccordionCompleteProps {
  zone: string;
  colectivas: ColectivaData[];
}

const AccordionComplete: React.FC<AccordionCompleteProps> = ({
  zone,
  colectivas
}) => {
  
  return (
    <Accordion >
        <Accordion.Item eventKey="0">
          <Accordion.Header className="accordion-reset-padding"><span className="text-24">{zone}</span></Accordion.Header>
          <Accordion.Body className="p-0">
            {colectivas.map((colectiva, index) => (
                <Accordion className="accordion-item-inner" key={index}>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span className="text-20 text-purple500 text-regular ph-10">{colectiva.name}</span>
                    </Accordion.Header>
                    <Accordion.Body className="pl-0 pr-0 pt-2 pb-2">
                      <table className="accordion-table">
                        {colectiva.women && (
                          <tr>
                            <td>
                              <span><strong>Mujeres</strong></span>
                            </td>
                            <td className="text-right">
                              <span className="text-14">{colectiva.women}</span>
                            </td>
                          </tr>
                        )}
                        {colectiva.lider && (
                          <tr>
                            <td>
                              <span><strong>Líder de Colectiva</strong></span>
                            </td>
                            <td className="text-right">
                              <span className="text-14">{colectiva.lider}</span>
                            </td>
                          </tr>
                        )}
                        {colectiva.acompanantes && (
                            <tr>
                              <td>
                                <span><strong>Acompañantes</strong></span>
                              </td>
                              <td className="text-right">
                                <span className="text-14">{colectiva.acompanantes}</span>
                              </td>
                            </tr>
                         )}

                        {colectiva.talleristas && (
                            <tr>
                              <td>
                                <span><strong>Talleristas</strong></span>
                              </td>
                              <td className="text-right">
                                <span className="text-14">{colectiva.talleristas}</span>
                              </td>
                            </tr>
                         )}

                        {colectiva.entregadoras && (
                             <tr>
                              <td>
                                <span><strong>Entregadoras</strong></span>
                              </td>
                              <td className="text-right">
                                <span className="text-14">{colectiva.entregadoras}</span>
                              </td>
                            </tr>
                         )}
                        
                       
                        {colectiva.aporte && (
                          
                          <tr>
                            <td>
                              <span><strong>Aporte Total</strong></span>
                            </td>
                            <td className="text-right">
                              <span className="text-14">${colectiva.aporte.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} CLP</span>
                            </td>
                          </tr>
                         )}
                       
                       {colectiva.url && (
                        <tr>
                          <td>
                            
                          </td>
                          <td className="text-right">
                            <a href={colectiva.url} className="text-decoration-none">Ver detalle</a>
                          </td>
                        </tr>
                       )}
                        
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
             ))}

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
  );
};

export default AccordionComplete;