import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IUser } from '../../../types/userInterface';

interface DemographicDataProps {
  user: Partial<IUser>;
  setUser?: React.Dispatch<React.SetStateAction<Partial<IUser>>>;
  readonly?: boolean;
}

const DemographicDataComponent: React.FC<DemographicDataProps> = ({
  user,
  setUser,
  readonly,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    const newData =
      name === 'currentAge'
        ? {
            [name]: value,
          }
        : {
            [name]: {
              name: value,
            },
          };
    if (setUser) {
      setUser((prevUser) => ({
        ...prevUser,
        datosSociodemograficos: {
          ...prevUser.datosSociodemograficos,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(newData as any),
        },
      }));
    }
  };
  return (
    <div className="form-block">
      <form>
        <div className="form-row">
          <label htmlFor="age" className="form-label label--icon">
            Edad
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Edad del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="currentAge"
            name="currentAge"
            placeholder="Edad del usuario"
            readOnly={readonly}
            value={user.datosSociodemograficos?.currentAge || ''}
            onChange={handleInputChange}
          />{' '}
          años
        </div>
        <div className="form-row">
          <label htmlFor="country" className="form-label label--icon">
            Nacionalidad
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Nacionalidad del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="country"
            name="country"
            placeholder="Nacionalidad del usuario"
            readOnly={readonly}
            value={user.datosSociodemograficos?.country?.name || ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="region" className="form-label label--icon">
            Región
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Región del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="state"
            name="state"
            placeholder="Región del usuario"
            readOnly={readonly}
            value={user.datosSociodemograficos?.state?.name || ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="comuna" className="form-label label--icon">
            Comuna
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Comuna del usuario</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="city"
            name="city"
            placeholder="Comuna del usuario"
            readOnly={readonly}
            value={user.datosSociodemograficos?.city?.name || ''}
            onChange={handleInputChange}
          />
        </div>
      </form>
    </div>
  );
};

export default DemographicDataComponent;
