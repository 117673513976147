import React from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import TagsComponent from '../../../../components/Forms/tags/tags.component';
import { TAGS_DATA } from '../../../../utils/tags';
import TagsManagerComponent from '../../../../components/Forms/tagsManager/tagsManager.component';
import EntregaTableComponent from '../../../../components/Elements/DataTable/entregaTable.component';
import { Accordion } from 'react-bootstrap';
import NotesComponent from '../../../../components/Elements/Notes/notes.component';
import AcompananteDataComponent from '../../../../components/Forms/adminDetail/acompananteDetail.component';
import ActionsEntregadoraButtonComponent from '../../../../components/Elements/Buttons/actionsEntregadora.component';

const EntregadoraDetailScreen: React.FC = () => {
  const tagsShow = ['tag-10']; // Definir los id de las tags que se quieren mostrar

  const tagsFiltered = TAGS_DATA.filter((tag) => tagsShow.includes(tag.id));
  return (
    <>
      <HeaderShared />
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom">
            <div className="row">
              <div className="col-12 mb-3">
                <a href="/mi-colectiva" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="view-head__data col-12">
                <div className="row">
                  <div className="view-detail col-md-8 col-12">
                    <h1 className="text-40">Eugenia</h1>
                    <p className="text-20">Entregadora</p>
                    <div className="view-detail__tags">
                      <TagsComponent tags={tagsFiltered} />
                    </div>
                    <div className="col-md-8 col-12 col-reset mb-4">
                      <table className="table-user-data">
                        <tbody>
                          <tr>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--message-clip"></i>{' '}
                                Registrada:
                              </p>
                            </td>
                            <td>18/01/2023 (24 horas)</td>
                          </tr>
                          <tr>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--user-clip"></i>{' '}
                                Entregas:
                              </p>
                            </td>
                            <td>120</td>
                          </tr>
                          <tr>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--pin"></i> Colectiva:
                              </p>
                            </td>
                            <td>Villa del Mar</td>
                          </tr>

                          <tr>
                            <td>
                              <p className="data-info">
                                <i className="icon icon--edit-clip"></i>Rol
                                Principal:
                              </p>
                            </td>
                            <td>Entregadora</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-12 col-reset">
                      <h4 className="mb-3">Especialista en</h4>
                      <TagsManagerComponent
                        admin={{}}
                        user={{}}
                        showLabel={false}
                      />
                    </div>
                  </div>
                  <aside className="view-detail__action no-flex  col-md-4 col-12">
                    <div className="view-detail__action-col col-12 col-reset mb-3">
                      <a href="/" className="btn btn--chat">
                        Ver Chat <i className="icon icon--chat"></i>
                      </a>
                      <ActionsEntregadoraButtonComponent />
                    </div>
                  </aside>
                </div>
              </div>
            </div>
            <div className="view-body__info col-md-8 col-12 pr-0">
              <div className="col-12 mt-5">
                <Accordion defaultActiveKey="0" className="accordion-regular">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Etiquetas</Accordion.Header>
                    <Accordion.Body className="pl-0 pr-0">
                      <TagsManagerComponent
                        admin={{}}
                        user={{}}
                        showLabel={true}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Notas</Accordion.Header>
                    <Accordion.Body>
                      <NotesComponent admin={{}} user={{}} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Información personal</Accordion.Header>
                    <Accordion.Body>
                      <AcompananteDataComponent />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="view-body module-top40">
              <div className="row">
                <div className="col-12  mb-4">
                  <h4 className="h3 mb-4">Entregas asignadas esta semana</h4>
                  <p className="text-24">
                    <strong>Entrega 20/Septiembre/2023</strong>
                  </p>
                  <EntregaTableComponent />
                </div>
                <div className="view-body__button col-12  mb-4">
                  <p className="text-24">
                    <strong>Entrega 21/Septiembre/2023</strong>
                  </p>
                  <a href="#" className="btn btn--type2">
                    Ver listado de mujeres
                  </a>
                </div>
                <div className="view-body__button col-12  mb-4">
                  <p className="text-24">
                    <strong>Entrega 23/Septiembre/2023</strong>
                  </p>
                  <a href="#" className="btn btn--type2">
                    Ver listado de mujeres
                  </a>
                </div>
                <div className="view-body__button col-12  mb-4">
                  <p className="text-24">
                    <strong>Entrega 25/Septiembre/2023</strong>
                  </p>
                  <a href="#" className="btn btn--type2">
                    Ver listado de mujeres
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default EntregadoraDetailScreen;
